import { Flex, Text, Box } from '@chakra-ui/react'
import { Loading } from 'components/ui'
import { Layout, Container, SubmitButton, SearchInput } from 'components/ui'
import { useProfile } from 'contexts/profileContext'
import { useTransactions } from 'contexts/transactionsProvider'
import { useLocalStorage } from 'hooks/useLocalStorage'
import React, { useCallback, useEffect, useState } from 'react'

import { TransactionTable } from './transactionsTable'

const SearchTransactionPluxee = () => {
  const { user } = useProfile()
  if (!user) return <Loading />

  const { removeStorageItem } = useLocalStorage()
  const removeCachedTransactionUUID = () => removeStorageItem('transactionUUID')

  return (
    <Layout isShowBackButton onPageBack={removeCachedTransactionUUID}>
      <Container>
        <Main removeCachedTransactionUUID={removeCachedTransactionUUID} />
      </Container>
    </Layout>
  )
}

export default SearchTransactionPluxee

type MainProps = {
  removeCachedTransactionUUID: () => void
}

const Main = ({ removeCachedTransactionUUID }: MainProps) => {
  const [isShowTable, setIsShowTable] = useState(false)
  const [isActiveButton, setIsActiveButton] = useState(false)
  const [isFirstRequest, setIsFirstRequest] = useState(false)

  const [value, setValue] = useState('')

  const {
    isLoading,
    isError,
    transaction,
    isSuccess,
    setTransactionUUID,
    isEmptyTransaction
  } = useTransactions()

  const { getStorageItem, setStorageItem } = useLocalStorage()

  const cachedTransactionUUID = getStorageItem('transactionUUID')

  useEffect(() => {
    if (cachedTransactionUUID) {
      setValue(cachedTransactionUUID)
      handleSearch()
    }
  }, [cachedTransactionUUID, value])

  useEffect(() => {
    window.addEventListener('beforeunload', removeCachedTransactionUUID)
    return () => {
      window.removeEventListener('beforeunload', removeCachedTransactionUUID)
    }
  }, [removeCachedTransactionUUID])

  const minTransactionUUIDCharacters = 36
  const isEmptyTable =
    (isEmptyTransaction && isSuccess && isFirstRequest) || isError

  const handleSearch = useCallback(() => {
    if (value.length < minTransactionUUIDCharacters) return

    setTransactionUUID(value)
    setStorageItem('transactionUUID', value)
    setIsFirstRequest(true)
  }, [setTransactionUUID, value])

  useEffect(() => {
    const hasValidValue = value.length === minTransactionUUIDCharacters

    if (hasValidValue) setIsActiveButton(true)
    else setIsActiveButton(false)
  }, [value])

  useEffect(() => {
    if (!isEmptyTransaction) setIsShowTable(true)
    else setIsShowTable(false)
  }, [transaction])

  function handleInputChange(inputValue: string) {
    removeCachedTransactionUUID()
    setValue(inputValue)
  }

  return (
    <Flex flexDir="column" bg="white" width="100%" p="40px">
      <Text
        mb="24px"
        fontSize="24px"
        fontWeight="700"
        letterSpacing="-1.2px"
        color="pluxee.text.primary"
      >
        Consultar transação
      </Text>

      <Flex alignItems="flex-end" mb="64px" gap="32px">
        <Box width="100%">
          <SearchInput
            size="1.5rem"
            customIcon="search"
            isError={isEmptyTable}
            onChange={handleInputChange}
            label="ID da transação"
            placeholder="Informe o ID da transação"
            value={value}
          />

          {isEmptyTable && (
            <Text color="#B30000" fontSize="xxxs" fontWeight="600" mt="4px">
              Transação não encontrada, verifique e tente novamente
            </Text>
          )}
        </Box>
        <SubmitButton
          onClick={handleSearch}
          isLoading={isLoading}
          isDisabled={!isActiveButton}
          decreaseMarginBottom
          customMarginBottom={!isEmptyTable ? '4px' : '25px'}
        />
      </Flex>

      {isShowTable && <TransactionTable />}
    </Flex>
  )
}
