import { Button, Flex, Text, VStack } from 'components/atoms'
import { Modal, ReactSelect } from 'components/ui'
import { UncontrolledInput } from 'components/ui/Input'
import { useUser } from 'contexts/userContext'
import { useAlert } from 'hooks/useAlert'
import { type User } from 'models/User'
import React, { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { CancelButton } from 'templates/wallet/operations/wrapper'
import { ErrorMessage, formatErrorMessage } from 'utils/errorUtils'

import { CreateAndUpdateInputs } from '../validations/createAndUpdateSchema'

type CreateAndUpdateUserModal = {
  isOpen: boolean
  onClose: () => void
  user?: User
}

const CreateAndUpdateUser = ({
  isOpen,
  onClose,
  user
}: CreateAndUpdateUserModal) => {
  const isEditing = user
  const { alert } = useAlert()

  const {
    roles,
    isLoadingRoles,
    createUser,
    updateUser,
    isLoadingCreate,
    isLoadingUpdate,
    updateUsersList
  } = useUser()

  const {
    watch,
    reset,
    control,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<CreateAndUpdateInputs>()

  const username = watch('username')?.trim().length >= 1
  const login = watch('login')?.trim().length >= 1

  const validFields = isEditing ? username : username && login

  const onSubmit: SubmitHandler<CreateAndUpdateInputs> = async formData => {
    if (!formData) {
      return alert({
        id: 'noFilledInputsUser',
        status: 'warning',
        title: 'Preencha todos os campos!'
      })
    }

    if (!isEditing) {
      createUser({
        name: formData.username,
        login: formData.login,
        id_role: Number(formData.role?.value)
      })
        .then(() => {
          reset()
          onClose()
          updateUsersList()

          alert({
            id: 'successCreateUser',
            status: 'success',
            title: 'Usuário criado com sucesso'
          })
        })
        .catch(err => {
          console.error(err)

          const errMsg = err?.response?.data?.message
          if (errMsg === 'login already exist') {
            const msg = formatErrorMessage(errMsg as ErrorMessage)
            return setError('login', { message: msg ?? '' })
          }

          return alert({
            id: 'errorCreateUser',
            status: 'error',
            title:
              'Ops! Tivemos um problema ao processar sua solicitação, por favor tente novamente'
          })
        })
    } else {
      updateUser(
        {
          name: formData.username,
          login: user.login,
          id_role: Number(formData.role?.value)
        },
        user.id
      )
        .then(() => {
          reset()
          onClose()
          updateUsersList()

          alert({
            id: 'successUpdateUser',
            status: 'success',
            title: 'Usuário atualizado com sucesso'
          })
        })
        .catch(err => {
          console.error(err)

          return alert({
            id: 'errorUpdateUser',
            status: 'error',
            title:
              'Ops! Tivemos um problema ao processar sua solicitação, por favor tente novamente'
          })
        })
    }
  }

  useEffect(() => {
    if (isEditing) {
      reset({
        username: user.name,
        role: { label: user.role?.name, value: user.role?.id }
      })
    }
  }, [isEditing])

  const modalFooter = (
    <Flex alignItems="center" gap="16px">
      <CancelButton
        onClick={() => {
          onClose()
          reset()
        }}
      />

      <SubmitButton
        title={!isEditing ? 'Criar usuário' : 'Salvar alterações'}
        isLoading={isLoadingCreate || isLoadingUpdate}
        onClick={handleSubmit(onSubmit)}
        isDisabled={!validFields || isLoadingCreate || isLoadingUpdate}
      />
    </Flex>
  )

  return (
    <Modal
      heading={!isEditing ? 'Criar usuário' : 'Editar usuário'}
      footer={modalFooter}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      onCloseComplete={() => {
        onClose()
        reset()
      }}
    >
      <VStack spacing="xxs">
        <Text
          w="100%"
          color="#221C46"
          fontSize="16px"
          fontWeight="500"
          letterSpacing="-0.8px"
        >
          {isEditing
            ? 'Edite as informações do usuário:'
            : 'Preencha as informações do novo usuário:'}
        </Text>

        <UncontrolledInput
          isRequired
          id="username"
          height="48px"
          control={control}
          placeholder="Ex: João Pedro"
          title="Nome do usuário"
          onChange={() => undefined}
          errorMessage={errors?.username?.message ?? ''}
          isInvalid={!!errors?.username?.message}
        />

        {!isEditing && (
          <UncontrolledInput
            id="login"
            isRequired
            title="Login"
            height="48px"
            control={control}
            onChange={() => undefined}
            placeholder="login.ext@sodexo.com"
            errorMessage={errors?.login?.message ?? ''}
            isInvalid={!!errors?.login?.message}
          />
        )}

        <Flex w="100%">
          <ReactSelect
            id={'role'}
            isRequired
            width="464px"
            height="48px"
            control={control}
            title={'Tipo de perfil'}
            isDisabled={isLoadingRoles}
            placeholder="Selecione um perfil"
            options={roles?.map(({ id, name }) => {
              return { value: id, label: name }
            })}
          />
        </Flex>
      </VStack>
    </Modal>
  )
}

export default CreateAndUpdateUser

const SubmitButton = ({
  isLoading,
  isDisabled,
  onClick,
  title
}: {
  title: string
  isLoading: boolean
  isDisabled: boolean
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
}) => {
  const defaultStyles = {
    boxShadow: !isDisabled && '4px 4px 0px 0px #A9A7B6 !important',
    background: !isDisabled && '#DEF3FB',
    color: !isDisabled && 'pluxee.interactive.primary'
  }

  return (
    <Button
      h="48px"
      p="4px 8px"
      color="white"
      bg="#1B51DC"
      border="3px solid"
      borderRadius="none"
      borderColor="#1B51DC"
      isLoading={isLoading}
      isDisabled={isDisabled}
      onClick={isDisabled ? undefined : onClick}
      _disabled={{
        bg: '#EFEFEF',
        color: '#B5B2BC',
        borderColor: '#EFEFEF',
        cursor: 'not-allowed'
      }}
      _focusVisible={{
        ...defaultStyles,
        boxShadow: !isDisabled && 'none !important'
      }}
      _hover={{
        boxShadow: !isDisabled && '4px 4px 0px 0px #A9A7B6 !important',
        background: !isDisabled && '#DEF3FB',
        color: !isDisabled && '#221C46',
        borderColor: !isDisabled && '#DEF3FB'
      }}
    >
      <Text fontSize="14px" fontWeight="700" letterSpacing="-0.7px">
        {title}
      </Text>
    </Button>
  )
}
