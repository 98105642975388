import { CreateAndUpdateUserProps } from 'contexts/userContext'
import { useMutation, useQuery } from 'react-query'
import { GetAllProps, UpdateProps, UserService } from 'services/userService'

export function useQueryGetAll() {
  return useQuery('getAll', () => getAll())
}

export function useQueryGetRoles() {
  return useQuery('getRoles', () => getListRoles())
}

export function useMutationGetAll() {
  return useMutation(getAll)
}

export function useMutationCreate() {
  return useMutation(create)
}

export function useMutationUpdate() {
  return useMutation(update)
}

export function useMutationRemove() {
  return useMutation(remove)
}

const getAll = async (filters?: GetAllProps) => {
  const response = await UserService.getAll(filters)
  return response.data
}

const getListRoles = async () => {
  const response = await UserService.getListRoles()
  return response.data
}

const create = async (user: CreateAndUpdateUserProps) => {
  const response = await UserService.create(user)
  return response.data
}

const update = async ({ user, id }: UpdateProps) => {
  const response = await UserService.update({ user, id })
  return response.data
}

const remove = async (id: number) => {
  const response = await UserService.remove(id)
  return response.data
}
