import { Flex } from '@chakra-ui/react'
import { SearchInput, SubmitButton } from 'components/ui'
import { useUser } from 'contexts/userContext'
import React from 'react'
import { objectIsEmpty } from 'utils/objectUtils'

const InputSearchUser = () => {
  const {
    users,
    getUsersByFilter,
    isLoadingGetAll,
    isSuccessGetAll,
    currentFilters,
    searchInputValue,
    setSearchInputValue,
    setCurrentRoleName,
    isSearchingByRole
  } = useUser()

  async function handleFilterByUserName(username: string) {
    setCurrentRoleName('')

    if (!username) {
      delete currentFilters.name
      getUsersByFilter({ ...currentFilters })
    } else {
      getUsersByFilter({ name: username, roleId: '' })
    }
  }

  const isError = isSuccessGetAll && objectIsEmpty(users) && !isSearchingByRole

  return (
    <Flex minW="665px" alignItems="flex-end" gap="32px">
      <SearchInput
        isError={isError}
        isOptionalLabel
        customHeight="48px"
        placeholder="Informe o nome de usuário"
        value={searchInputValue}
        onChange={event => setSearchInputValue(event)}
        label={'Buscar usuário'}
        customIcon="search"
        imageIcon="/img/users/searchicon.svg"
        showClearButton={searchInputValue.length >= 1}
        errorMessage="Usuário não encontrado, verifique e tente novamente"
        onClear={() => {
          setSearchInputValue('')
          handleFilterByUserName('')
        }}
      />

      <SubmitButton
        onClick={() => handleFilterByUserName(searchInputValue)}
        isLoading={isLoadingGetAll}
        isDisabled={!searchInputValue.trim()}
        decreaseMarginBottom
        customMarginBottom={!isError ? '5px' : '26.5px'}
      />
    </Flex>
  )
}

export default InputSearchUser
