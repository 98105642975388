import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { Control, Controller } from 'react-hook-form'
import Select, {
  components,
  SingleValue,
  StylesConfig,
  OptionProps,
  DropdownIndicatorProps
} from 'react-select'

interface OptionType {
  value: string
  label: string
}

type CustomSelectProps = {
  id: string
  options: OptionType[]
  width: string
  height?: string
  title: string
  isDisabled?: boolean
  placeholder: string
  isInvalid?: boolean
  isRequired?: boolean
  errorMessage?: string
  requiredMessage?: string
  control: Control<any>
  onChange?: (event: SingleValue<OptionType>) => void
}

const ReactSelect = ({
  options,
  placeholder,
  isDisabled,
  width,
  height,
  control,
  id,
  onChange,
  title,
  errorMessage,
  isInvalid,
  isRequired,
  requiredMessage
}: CustomSelectProps) => {
  const [labelColor, setLabelColor] = useState('pluxee.text.primary')
  const [isFocused, setIsFocused] = useState(false)

  const handleOnMouseEnter = () => setLabelColor('pluxee.text.link')
  const handleOnMouseLeave = () => {
    !isFocused && setLabelColor('pluxee.text.primary')
  }

  const handleOnBlur = () => {
    setIsFocused(false)
    setLabelColor('pluxee.text.primary')
  }

  const handleOnFocus = () => {
    setIsFocused(true)
    setLabelColor('pluxee.text.link')
  }

  const customStyles: StylesConfig<OptionType, false> = {
    control: (provided, state: any) => ({
      ...provided,
      fontSize: '16px',
      fontWeight: '600',
      color: '#221C46',
      borderRadius: 'none',
      letterSpacing: '-0.8px',
      outline: 'none',
      border:
        state.isFocused || state.menuIsOpen
          ? '3px solid #1B51DC'
          : '1px solid #D1CFD7',
      borderColor: 'transparent',
      boxShadow: state.isFocused ? 'none' : provided.boxShadow,
      backgroundColor: state.isSelected ? '#FCF1EE' : 'brand.primary-light',
      width,
      height,
      ':hover': {
        ...provided[':hover'],
        borderColor: '#1B51DC',
        backgroundColor: '#DEF3FB',
        color: '#1B51DC'
      }
    }),

    placeholder: (provided, state) => ({
      ...provided,
      color: state.isFocused ? '#221C46' : '#908C99',
      ':hover': {
        color: '#1B51DC'
      }
    }),

    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: '#221C46',
      transition: 'all 0.5s ease',
      transform: state.isFocused ? 'rotate(180deg)' : '',
      paddingRight: '12px'
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: 'white',
      color: state.isSelected ? '#1B51DC' : '#221C46',
      padding: '12px 8px',
      ':active': {
        ...provided[':active'],
        backgroundColor: '#BFDFFF'
      },
      ':hover': {
        ...provided[':hover'],
        backgroundColor: '#DEF3FB'
      }
    }),

    indicatorSeparator: provided => ({
      ...provided,
      display: 'none'
    }),

    menu: provided => ({
      ...provided,
      border: 'none',
      boxShadow: 'none',
      marginTop: '2px',
      background: 'transparent'
    }),

    menuList: provided => ({
      ...provided,
      fontSize: '14px',
      fontWeight: '600',
      color: '#221C46',
      letterSpacing: '-0.7px',
      lineHeight: '20.3px',
      borderRadius: 'none',
      border: '1px solid #D1CFD7',
      overflow: 'hidden',
      boxShadow: '4px 4px 0px 0px #A9A7B6',
      marginBottom: '20px',
      background: 'white'
    })
  }

  return (
    <FormControl id={id} isInvalid={isInvalid}>
      <FormLabel
        fontSize="14px"
        fontWeight="600"
        color={isInvalid ? 'pluxee.text.primary' : labelColor}
        display="flex"
        gap="4px"
      >
        <Text letterSpacing="-0.7px">{title}</Text>
        {isRequired && <Text color="#CC1480">*</Text>}
      </FormLabel>

      <Controller
        name={id}
        rules={{
          required: {
            value: Boolean(isRequired),
            message: requiredMessage ?? ''
          }
        }}
        control={control}
        render={({ field }) => (
          <Flex
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          >
            <Select<OptionType, false>
              {...field}
              onChange={event => {
                onChange && onChange(event)
                field.onChange(event)
              }}
              styles={customStyles}
              options={options}
              placeholder={placeholder}
              isDisabled={isDisabled}
              multiValueBackground={undefined}
              isSearchable={false}
              menuPlacement="auto"
              components={{
                Option: CustomOption,
                DropdownIndicator: CustomDropdownIndicator
              }}
            />
          </Flex>
        )}
      />

      {!!errorMessage && (
        <FormErrorMessage fontSize="smaller">{errorMessage}</FormErrorMessage>
      )}
    </FormControl>
  )
}

export default ReactSelect

const CheckIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.99805 11.998L10 16.9999L19.002 7.99795L17.5877 6.58374L10 14.1715L6.41226 10.5837L4.99805 11.998Z"
      fill="#1B51DC"
    />
  </svg>
)

const CustomOption = (props: OptionProps<OptionType, false>) => (
  <components.Option {...props}>
    <Flex justifyContent="space-between">
      {props.label}
      {props.isSelected && <CheckIcon />}
    </Flex>
  </components.Option>
)

const CustomDropdownIndicator = (
  props: DropdownIndicatorProps<OptionType, false>
) => (
  <components.DropdownIndicator {...props}>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.0001 6.9999L8 11.9998L12.9999 6.9999L12.2928 6.29279L8 10.5856L3.70721 6.29279L3.0001 6.9999Z"
        fill="#221C46"
      />
    </svg>
  </components.DropdownIndicator>
)
