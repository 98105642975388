import { useDisclosure } from '@chakra-ui/react'
import { Text, Box } from 'components/atoms'
import { ScheduleStatus } from 'components/ui'
import Table, { CellProps } from 'components/ui/Table'
import { useProfile } from 'contexts/profileContext'
import { useSchedules } from 'contexts/scheduleContext'
import { usePagination } from 'hooks/usePagination'
import { formatCNPJ, formatCPF, translate } from 'internationalization'
import { Schedules } from 'models/Schedules'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Skeleton from 'templates/customer/table/skeleton'
import { formatDateToBrazilianFormat } from 'utils/dateUtils'
import { limitString } from 'utils/stringUtils'
import { formatTransactionId } from 'utils/transactionsUtils'

import { CancelOrder } from './cancelOrder'
import { ScheduleOptions } from './scheduleOptions'

export const ScheduleTable = () => {
  const [selectedOrderId, setSelectedOrderId] = useState<string>()
  const { isBackoffice4c } = useProfile()
  const navigate = useNavigate()

  const {
    schedules,
    schedulesPagination,
    isLoadingSchedulesByOrder,
    currentFilters,
    isLoadingSchedulesByCustomer,
    getSchedulesByCustomerId
  } = useSchedules()

  const {
    isOpen: isOpenCancelOrder,
    onOpen: onOpenCancelOrder,
    onClose: onCloseCancelOrder
  } = useDisclosure()

  const { currentPage, handleNextPage, handlePrevPage, handleOnChangePage } =
    usePagination({ initialPage: schedulesPagination?.currentPage ?? 0 })

  const columns: CellProps[] = [
    {
      title: translate('commons.date'),
      render: (data: Record<keyof Schedules, any>) => {
        return (
          <CustomText text={formatDateToBrazilianFormat(data.scheduledDate)} />
        )
      }
    },
    {
      title: 'ID do pedido',
      render: (data: Record<keyof Schedules, any>) => {
        return <CustomText text={formatTransactionId(data.orderId)} />
      }
    },
    {
      title: 'Código do cliente',
      render: (data: Record<keyof Schedules, any>) => {
        return <CustomText text={data.customerId} />
      }
    },
    {
      title: 'Empresa',
      render: (data: Record<keyof Schedules, any>) => (
        <Box maxW="230px" lineHeight="150%">
          <CustomText text={limitString(data.customerTradeName, 45)} />
        </Box>
      )
    },
    {
      title: 'CNPJ/CPF',
      render: (data: Record<keyof Schedules, any>) => {
        const isCpf = data.document?.length === 11
        const formattedDocument = isCpf
          ? formatCPF(data.document)
          : formatCNPJ(data.document)

        return <CustomText text={formattedDocument} />
      }
    },
    {
      title: translate('commons.status'),
      render: (data: Record<keyof Schedules, any>) => {
        return <ScheduleStatus status={data.status} />
      }
    },
    {
      title: '',
      style: { textAlign: 'center' },
      width: '5%',
      render: (data: Record<keyof Schedules, any>) => {
        const isShowCancelOrder = data.status === 'SCHEDULED' && isBackoffice4c

        return (
          <ScheduleOptions
            orderId={data.orderId}
            isShowCancelOrder={isShowCancelOrder}
            onOpenCancelOrder={onOpenCancelOrder}
            setSelectedOrderId={() => setSelectedOrderId(data.orderId)}
          />
        )
      }
    }
  ]

  const handleOnClickRow = (orderId: string) =>
    navigate(`/schedules/${orderId}`)

  useEffect(() => {
    getSchedulesByCustomerId({
      customerId: String(currentFilters?.customerId),
      filterByScheduleds: Boolean(currentFilters?.filterByScheduleds),
      pageNumber: String(currentPage)
    })
  }, [currentPage])

  const isPaginated = schedules.length >= 20

  const hasSchedules = schedules.length > 1
  const isLoading = isLoadingSchedulesByOrder || isLoadingSchedulesByCustomer

  if (schedules.length === 0) return <></>
  if (isLoading && !hasSchedules) return <Skeleton />

  return (
    <>
      <Table
        textType="initial"
        minWidth="100%"
        size="sm"
        columns={columns}
        list={schedules}
        isPaginated={isPaginated}
        currentPage={currentPage}
        totalPages={schedulesPagination?.totalPages}
        totalResults={schedulesPagination?.totalResults}
        handlePrevPage={handlePrevPage}
        handleNextPage={handleNextPage}
        handleOnChangePage={pageNumber => handleOnChangePage(pageNumber)}
        onClickRow={data => handleOnClickRow(data.orderId)}
      />

      {selectedOrderId && (
        <CancelOrder
          isOpen={isOpenCancelOrder}
          onClose={onCloseCancelOrder}
          orderId={selectedOrderId}
        />
      )}
    </>
  )
}

const CustomText = ({ text }: { text: string }) => (
  <Text color="#463F5F" fontWeight="500" fontSize="14px" letterSpacing="-0.7px">
    {text}
  </Text>
)
