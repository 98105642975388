import {
  useMutationCreate,
  useMutationGetAll,
  useMutationRemove,
  useMutationUpdate,
  useQueryGetAll,
  useQueryGetRoles
} from 'hooks/queries/userQueries'
import { Pagination } from 'models/Pagination'
import { User } from 'models/User'
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react'
import { GetAllProps } from 'services/userService'

export type CreateAndUpdateUserProps = {
  login: string
  name: string
  id_role: number
}

type ErrorCreateUser = {
  response: {
    data: {
      message: string
    }
  }
}

type Role = Record<'id' | 'name', string>

type RequestStatus = 'error' | 'idle' | 'loading' | 'success'

export type ContextType = {
  users: User[]
  backupUsers: User[]
  roles: Role[]

  selectedUser?: User
  setSelectedUser: (value: undefined | User) => void

  isSuccessGetAll: boolean
  isSuccessCreate: boolean
  isSuccessUpdate: boolean
  isSuccessRemove: boolean

  isFetching: boolean

  isLoading: boolean
  isLoadingGetAll: boolean
  isLoadingRoles: boolean
  isLoadingCreate: boolean
  isLoadingUpdate: boolean
  isLoadingRemove: boolean

  isErrorGetAll: boolean
  isErrorCreate: boolean
  isErrorRemove: boolean

  errorCreateUser: ErrorCreateUser

  statusGetAll: RequestStatus
  statusCreate: RequestStatus
  statusUpdate: RequestStatus

  usersPagination?: Pagination
  currentFilters: GetAllProps

  getUsersByFilter: (filters: GetAllProps) => Promise<void>
  createUser: (user: CreateAndUpdateUserProps) => Promise<void>
  updateUser: (user: CreateAndUpdateUserProps, id: number) => Promise<void>
  removeUser: (id: number) => Promise<void>
  updateUsersList: () => void

  searchInputValue: string
  setSearchInputValue: (value: string) => void

  currentRoleName: string
  setCurrentRoleName: (value: string) => void

  selectedRoleId?: string
  setSelectedRoleId: (value: string) => void

  isSearchingByRole: boolean
  setIsSearchingByRole: (value: boolean) => void
}

export const Context = createContext({} as ContextType)

export type UserProviderProps = {
  children: ReactNode
}

export const UserProvider = ({ children }: UserProviderProps) => {
  const [users, setUsers] = useState<User[]>([])
  const [backupUsers, setBackupUsers] = useState<User[]>([])
  const [usersPagination, setUsersPagination] = useState<Pagination>()
  const [selectedUser, setSelectedUser] = useState<User>()

  const [roles, setRoles] = useState<Role[]>([])
  const [currentRoleName, setCurrentRoleName] = useState('')
  const [selectedRoleId, setSelectedRoleId] = useState<string>('ALL')
  const [isSearchingByRole, setIsSearchingByRole] = useState(false)

  const [currentFilters, setCurrentFilters] = useState<GetAllProps>({})
  const [searchInputValue, setSearchInputValue] = useState('')

  const queryRoles = useQueryGetRoles()
  const isLoadingRoles = roles.length <= 0

  const queryUser = useQueryGetAll()
  const { isLoading, isFetching, refetch } = queryUser

  const usersData = queryUser.data?.users

  const updateUsersList = refetch

  const mutateGetAll = useMutationGetAll()
  const {
    isLoading: isLoadingGetAll,
    isSuccess: isSuccessGetAll,
    isError: isErrorGetAll,
    status: statusGetAll,
    data: queryUserByFilters
  } = mutateGetAll

  const mutateCreate = useMutationCreate()
  const {
    isLoading: isLoadingCreate,
    isSuccess: isSuccessCreate,
    isError: isErrorCreate,
    status: statusCreate,
    error: errorCreateUser
  } = mutateCreate

  const mutateUpdate = useMutationUpdate()
  const {
    isLoading: isLoadingUpdate,
    isSuccess: isSuccessUpdate,
    status: statusUpdate
  } = mutateUpdate

  const mutateRemove = useMutationRemove()
  const {
    isLoading: isLoadingRemove,
    isError: isErrorRemove,
    isSuccess: isSuccessRemove
  } = mutateRemove

  const getUsersByFilter = async (filters: GetAllProps) => {
    setCurrentFilters(filters)
    try {
      const response = await mutateGetAll.mutateAsync(filters)
      setBackupUsers(users)
      setUsers(response.users)
      setUsersPagination({
        currentPage: response?.page?.currentPage,
        totalPages: response?.page?.totalPages,
        totalResults: response?.page?.totalItems
      })
    } catch (error) {
      console.error('Erro ao buscar usuários:', error)
      setUsers(backupUsers)
    }
  }

  const createUser = async (user: CreateAndUpdateUserProps) => {
    return await mutateCreate.mutateAsync(user)
  }

  const updateUser = async (user: CreateAndUpdateUserProps, id: number) => {
    return await mutateUpdate.mutateAsync({ user, id })
  }

  const removeUser = async (id: number) => {
    await mutateRemove.mutateAsync(id)
  }

  useEffect(() => {
    if (usersData) {
      if (usersData.length >= 1) setBackupUsers(usersData)

      setUsers(usersData)
      setUsersPagination({
        currentPage: queryUser.data?.page?.currentPage,
        totalPages: queryUser.data?.page?.totalPages,
        totalResults: queryUser.data?.page?.totalItems
      })
    }
  }, [usersData, backupUsers])

  useEffect(() => {
    if (queryUserByFilters) {
      if (queryUserByFilters.length >= 1) setBackupUsers(usersData)

      setUsers(queryUserByFilters.users)
      setUsersPagination({
        currentPage: queryUserByFilters?.page?.currentPage,
        totalPages: queryUserByFilters?.page?.totalPages,
        totalResults: queryUserByFilters?.page?.totalItems
      })
    }
  }, [queryUserByFilters, backupUsers])

  useEffect(() => {
    if (queryRoles.data) setRoles(queryRoles.data)
  }, [queryRoles.data])

  return (
    <Context.Provider
      value={{
        currentFilters,
        users,
        backupUsers,
        roles,
        isSuccessCreate,
        isSuccessUpdate,
        isSuccessRemove,
        isFetching,
        isLoading,
        isLoadingRoles,
        isLoadingCreate,
        isLoadingUpdate,
        isLoadingRemove,
        isErrorCreate,
        isErrorRemove,
        isLoadingGetAll,
        isSuccessGetAll,
        isErrorGetAll,
        statusGetAll,
        createUser,
        updateUser,
        removeUser,
        updateUsersList,
        getUsersByFilter,
        statusCreate,
        statusUpdate,
        usersPagination,
        searchInputValue,
        setSearchInputValue,
        errorCreateUser: errorCreateUser as ErrorCreateUser,
        selectedUser,
        setSelectedUser,
        selectedRoleId,
        setSelectedRoleId,
        currentRoleName,
        setCurrentRoleName,
        isSearchingByRole,
        setIsSearchingByRole
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useUser = () => {
  const context = useContext(Context)
  return context
}
