import { Flex, Text } from '@chakra-ui/react'
import { Container, Layout } from 'components/ui'
import React from 'react'

import { SearchArea } from './searchArea'

const SchedulesTemplate = () => {
  return (
    <Layout isShowBackButton>
      <Container>
        <Main />
      </Container>
    </Layout>
  )
}

export default SchedulesTemplate

const Main = () => {
  return (
    <Flex flexDir="column" bg="white" width="100%" p="40px">
      <Heading />
      <SearchArea />
    </Flex>
  )
}

const Heading = () => (
  <Text
    mb="24px"
    fontSize="24px"
    fontWeight="700"
    letterSpacing="-1.2px"
    color="pluxee.text.primary"
  >
    Consultar agendamentos de crédito
  </Text>
)
