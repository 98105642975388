import {
  useQueryAntecipateOrder,
  useQueryCancelOrder,
  useQueryGetByCpf,
  useQueryGetDetails,
  useQueryGetScheduleHistory,
  useQueryGetSchedulesByCustomer,
  useQueryGetSchedulesByOrder
} from 'hooks/queries/scheduleQueries'
import type { Pagination } from 'models/Pagination'
import { AntecipateOrderProps } from 'models/request/AntecipateOrder'
import type {
  ScheduleConsumersList,
  ScheduleHistory,
  Schedules,
  SchedulesDetails
} from 'models/Schedules'
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'

export type CancelOrderProps = {
  orderId: string
  occurencyId: string
  userName: string
  sourceIp: string
}

type CurrentFilters = {
  customerId: string
  filterByScheduleds?: boolean
  pageNumber?: string
}

export type ContextType = {
  schedules: Schedules[]
  schedulesPagination?: Pagination
  schedulesDetailsPage?: Pagination
  scheduleHistory: ScheduleHistory[]
  consumersList: ScheduleConsumersList

  schedulesDetails?: SchedulesDetails

  isLoadingSchedulesByOrder: boolean
  isErrorSchedulesByOrder: boolean
  isSuccessSchedulesByOrder: boolean

  isLoadingSchedulesByCustomer: boolean
  isErrorSchedulesByCustomer: boolean
  isSuccessSchedulesByCustomer: boolean

  isLoadingDetails: boolean
  isErrorDetails: boolean

  isLoadingCancelOrder: boolean
  isErrorCancelOrder: boolean
  isSuccessCancelOrder: boolean

  isLoadingHistory: boolean
  isSuccessHistory: boolean
  isErrorHistory: boolean

  isLoadingConsumers: boolean
  isErrorConsumers: boolean
  isSuccessConsumers: boolean

  isLoadingAntecipateOrder: boolean
  isErrorAntecipateOrder: boolean
  isSuccessAntecipateOrder: boolean

  scheduleDateForCalendar?: string
  setScheduleDateForCalendar: (value: string) => void

  hideConsumersList: boolean
  setHideConsumersList: (value: boolean) => void

  getScheduleHistory: (orderId: string) => void
  getSchedulesByOrderId: (orderId: string) => void

  currentFilters?: CurrentFilters
  getSchedulesByCustomerId: ({
    customerId,
    filterByScheduleds,
    pageNumber
  }: {
    customerId: string
    filterByScheduleds?: boolean
    pageNumber?: string
  }) => void

  getScheduleDetails: (orderId: string) => void
  cancelOrder: ({
    orderId,
    occurencyId,
    userName,
    sourceIp
  }: CancelOrderProps) => Promise<void>
  getConsumersByCpf: ({
    orderId,
    cpf
  }: {
    orderId: string
    cpf: string
  }) => void

  antecipateOrder: ({
    orderId,
    scheduledDate,
    sourceIp,
    userName
  }: AntecipateOrderProps) => Promise<void>

  originalScheduleDate: string | undefined
  setOriginalScheduleDate: (value: string) => void
}

export const Context = createContext({} as ContextType)

type SchedulesProviderProps = {
  children: ReactNode
}

export const SchedulesProvider = ({ children }: SchedulesProviderProps) => {
  const [schedules, setSchedules] = useState<Schedules[]>([])
  const [schedulesPagination, setSchedulesPagination] = useState<Pagination>()

  const [schedulesDetailsPage, setSchedulesDetailsPage] = useState<Pagination>()
  const [schedulesDetails, setSchedulesDetails] = useState<SchedulesDetails>()

  const [hideConsumersList, setHideConsumersList] = useState(true)
  const [currentFilters, setCurrentFilters] = useState<CurrentFilters>()

  const [originalScheduleDate, setOriginalScheduleDate] = useState<string>()

  const [scheduleDateForCalendar, setScheduleDateForCalendar] =
    useState<string>()

  const mutateGetHistory = useQueryGetScheduleHistory()
  const {
    data: scheduleHistory,
    isLoading: isLoadingHistory,
    isSuccess: isSuccessHistory,
    isError: isErrorHistory
  } = mutateGetHistory

  const mutateGetByOrder = useQueryGetSchedulesByOrder()
  const {
    data: dataByOrder,
    isLoading: isLoadingSchedulesByOrder,
    isError: isErrorSchedulesByOrder,
    isSuccess: isSuccessSchedulesByOrder
  } = mutateGetByOrder

  const mutateGetByCustomer = useQueryGetSchedulesByCustomer()
  const {
    data: dataByCustomer,
    isLoading: isLoadingSchedulesByCustomer,
    isError: isErrorSchedulesByCustomer,
    isSuccess: isSuccessSchedulesByCustomer
  } = mutateGetByCustomer

  const mutateGetDetails = useQueryGetDetails()
  const {
    data: dataByDetails,
    isLoading: isLoadingDetails,
    isError: isErrorDetails
  } = mutateGetDetails

  const mutateAntecipateOrder = useQueryAntecipateOrder()
  const {
    isLoading: isLoadingAntecipateOrder,
    isError: isErrorAntecipateOrder,
    isSuccess: isSuccessAntecipateOrder
  } = mutateAntecipateOrder

  const mutateCancelOrder = useQueryCancelOrder()
  const {
    isLoading: isLoadingCancelOrder,
    isError: isErrorCancelOrder,
    isSuccess: isSuccessCancelOrder
  } = mutateCancelOrder

  const mutateGetByCpf = useQueryGetByCpf()
  const {
    data: consumersList,
    isLoading: isLoadingConsumers,
    isError: isErrorConsumers,
    isSuccess: isSuccessConsumers
  } = mutateGetByCpf

  const getConsumersByCpf = useCallback(
    async ({ orderId, cpf }: { orderId: string; cpf: string }) => {
      await mutateGetByCpf.mutateAsync({ orderId, cpf })
    },
    [mutateGetByCpf]
  )

  const getScheduleHistory = useCallback(
    async (orderId: string) => {
      await mutateGetHistory.mutateAsync(orderId)
    },
    [mutateGetHistory]
  )

  const getSchedulesByOrderId = useCallback(
    async (orderId: string) => {
      await mutateGetByOrder.mutateAsync(orderId)
    },
    [mutateGetByOrder]
  )

  const getSchedulesByCustomerId = useCallback(
    async ({
      customerId,
      filterByScheduleds,
      pageNumber
    }: {
      customerId: string
      filterByScheduleds?: boolean
      pageNumber?: string
    }) => {
      setCurrentFilters({
        customerId,
        filterByScheduleds,
        pageNumber
      })

      await mutateGetByCustomer.mutateAsync({
        customerId,
        filterByScheduleds,
        pageNumber
      })
    },
    [mutateGetByCustomer]
  )

  const getScheduleDetails = useCallback(
    async (orderId: string) => {
      await mutateGetDetails.mutateAsync(orderId)
    },
    [mutateGetDetails]
  )

  const antecipateOrder = useCallback(
    async (props: AntecipateOrderProps) => {
      await mutateAntecipateOrder.mutateAsync({
        ...props
      })
    },
    [mutateAntecipateOrder]
  )

  const cancelOrder = useCallback(
    async ({ orderId, occurencyId, userName, sourceIp }: CancelOrderProps) => {
      await mutateCancelOrder.mutateAsync({
        orderId,
        occurencyId,
        userName,
        sourceIp
      })
    },
    [mutateCancelOrder]
  )

  useEffect(() => {
    if (isErrorSchedulesByCustomer || isErrorSchedulesByOrder) {
      setSchedules([])
      setSchedulesPagination(undefined)
    }
  }, [isErrorSchedulesByCustomer, isErrorSchedulesByOrder])

  useEffect(() => {
    if (dataByDetails) {
      setSchedulesDetails(dataByDetails)
      setSchedulesDetailsPage(dataByDetails?.beneficiaryDetails?.page)
    }
  }, [dataByDetails])

  useEffect(() => {
    const schedulesData = dataByOrder?.items
    const schedulesPagination = dataByOrder?.page

    if (schedulesData) {
      setSchedules(schedulesData)
      setSchedulesPagination({
        currentPage: schedulesPagination?.currentPage,
        totalPages: schedulesPagination?.totalPages,
        totalResults: schedulesPagination?.totalItems
      })
    }
  }, [dataByOrder])

  useEffect(() => {
    const schedulesData = dataByCustomer?.items
    const schedulesPagination = dataByCustomer?.page

    if (schedulesData) {
      setSchedules(schedulesData)
      setSchedulesPagination({
        currentPage: schedulesPagination?.currentPage,
        totalPages: schedulesPagination?.totalPages,
        totalResults: schedulesPagination?.totalItems
      })
    }
  }, [dataByCustomer])

  return (
    <Context.Provider
      value={{
        currentFilters,
        schedules,
        schedulesPagination,
        schedulesDetailsPage,
        scheduleHistory,
        schedulesDetails,
        consumersList,
        isLoadingSchedulesByOrder,
        isErrorSchedulesByOrder,
        isLoadingSchedulesByCustomer,
        isErrorSchedulesByCustomer,
        isLoadingDetails,
        isErrorDetails,
        getSchedulesByOrderId,
        getSchedulesByCustomerId,
        getScheduleDetails,
        getConsumersByCpf,
        getScheduleHistory,
        cancelOrder,
        isErrorCancelOrder,
        isLoadingCancelOrder,
        isSuccessCancelOrder,
        isLoadingHistory,
        isErrorHistory,
        isSuccessHistory,
        isErrorConsumers,
        isLoadingConsumers,
        isSuccessConsumers,
        hideConsumersList,
        setHideConsumersList,
        isSuccessSchedulesByCustomer,
        isSuccessSchedulesByOrder,
        scheduleDateForCalendar,
        setScheduleDateForCalendar,
        isErrorAntecipateOrder,
        isLoadingAntecipateOrder,
        isSuccessAntecipateOrder,
        antecipateOrder,
        originalScheduleDate,
        setOriginalScheduleDate
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useSchedules = () => {
  const context = useContext(Context)

  return context
}
