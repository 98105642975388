import { Box, Flex } from '@chakra-ui/react'
import { Checkbox, SearchInput, SubmitButton } from 'components/ui'
import { useSchedules } from 'contexts/scheduleContext'
import { useAlert } from 'hooks/useAlert'
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'

import { ScheduleTable } from './scheduleTable/table'

export const SearchArea = () => {
  const [isShowAlert, setIsShowAlert] = useState(false)
  const [showTable, setShowTable] = useState(false)
  const [showCheckbox, setShowCheckbox] = useState(false)
  const [isActiveButton, setIsActiveButton] = useState(false)

  const [orderCode, setOrderCode] = useState('')
  const [clientCode, setClientCode] = useState('')
  const [grantedRefetch, setGrantedRefetch] = useState(false)

  const maxFailureCount = 2
  const [failureCount, setIsFailureCount] = useState(0)

  const { alert } = useAlert()
  const [listAllOrders, setListAllOrders] = useState(true)

  const {
    schedules,
    getSchedulesByOrderId,
    getSchedulesByCustomerId,
    isLoadingSchedulesByOrder,
    isLoadingSchedulesByCustomer,
    isErrorSchedulesByOrder,
    isSuccessSchedulesByOrder,
    isErrorSchedulesByCustomer
  } = useSchedules()

  const isSuccessByClientCode = schedules?.length > 1

  const hasOrderCode = orderCode.length >= 1
  const hasClientCode = clientCode.length >= 1

  const isRefetchByOrderCode = Boolean(hasOrderCode)
  const isRefetchByClientCode = Boolean(hasClientCode)
  const isBothFieldsAreFilled = isRefetchByOrderCode && isRefetchByClientCode

  const isLoading = isLoadingSchedulesByOrder || isLoadingSchedulesByCustomer
  const isError = isErrorSchedulesByOrder || isErrorSchedulesByCustomer

  const handleGetSchedules = useCallback(() => {
    const isEmptyFields = !hasOrderCode && !hasClientCode
    const isBothFieldsAreFilled = hasOrderCode && hasClientCode

    setIsFailureCount(0)
    setShowTable(false)
    setListAllOrders(true)

    if (isEmptyFields) {
      alert({
        id: 'notFilledFieldsAlert',
        status: 'warning',
        title: 'Preencha ao menos um dos campos'
      })
      setShowTable(false)
      setShowCheckbox(false)
      setIsShowAlert(false)
      return
    }

    if (isBothFieldsAreFilled) setIsShowAlert(true)

    if (isBothFieldsAreFilled || hasOrderCode) {
      setShowCheckbox(false)
    }

    if (hasOrderCode) {
      setGrantedRefetch(true)
      getSchedulesByOrderId(orderCode)

      setShowTable(true)
      setShowCheckbox(false)

      return
    }

    if (hasClientCode) {
      setGrantedRefetch(true)
      getSchedulesByCustomerId({
        customerId: clientCode,
        filterByScheduleds: true
      })

      setShowCheckbox(true)
      setShowTable(true)

      return
    }
  }, [orderCode, clientCode, showCheckbox])

  useLayoutEffect(() => {
    if (hasOrderCode || hasClientCode) setIsActiveButton(true)
    else setIsActiveButton(false)
  }, [orderCode, clientCode])

  useEffect(() => {
    if (!isBothFieldsAreFilled && isRefetchByOrderCode && grantedRefetch) {
      setGrantedRefetch(false)
      setShowTable(true)
      getSchedulesByOrderId(orderCode)
    }
  }, [isRefetchByOrderCode, isBothFieldsAreFilled, grantedRefetch])

  useEffect(() => {
    if (!isBothFieldsAreFilled && isRefetchByClientCode && grantedRefetch) {
      setGrantedRefetch(false)

      setShowCheckbox(true)
      setShowTable(true)

      getSchedulesByCustomerId({
        customerId: clientCode,
        filterByScheduleds: true
      })
    }
  }, [isRefetchByClientCode, isBothFieldsAreFilled, grantedRefetch])

  useEffect(() => {
    if (isBothFieldsAreFilled && grantedRefetch) {
      getSchedulesByOrderId(orderCode)
      setGrantedRefetch(false)
    }
  }, [isBothFieldsAreFilled, grantedRefetch, orderCode])

  useEffect(() => {
    getSchedulesByCustomerId({
      customerId: clientCode,
      filterByScheduleds: listAllOrders
    })
  }, [listAllOrders])

  useEffect(() => {
    if (isErrorSchedulesByOrder && hasClientCode) {
      getSchedulesByCustomerId({
        customerId: clientCode,
        filterByScheduleds: true
      })
    }
  }, [isErrorSchedulesByOrder, hasClientCode])

  useEffect(() => {
    if (isBothFieldsAreFilled && isShowAlert) {
      const isShowClientAlert =
        isSuccessByClientCode &&
        !isSuccessSchedulesByOrder &&
        !isErrorSchedulesByCustomer &&
        isErrorSchedulesByOrder

      const isShowOrderCodeAlert =
        isSuccessSchedulesByOrder &&
        !isSuccessByClientCode &&
        !isErrorSchedulesByOrder

      const codeAlertDiv = document.querySelector(
        '#successOrderCode'
      ) as HTMLDivElement

      const clientAlertDiv = document.querySelector(
        '#successClientCode'
      ) as HTMLDivElement

      if (isShowOrderCodeAlert) {
        if (clientAlertDiv) clientAlertDiv.style.display = 'none'
        if (codeAlertDiv) codeAlertDiv.style.display = 'flex'

        alert({
          id: 'successOrderCode',
          status: 'info',
          title: 'Pedido encontrado com sucesso!'
        })

        return
      }

      if (isShowClientAlert) {
        if (codeAlertDiv) codeAlertDiv.style.display = 'none'
        if (clientAlertDiv) clientAlertDiv.style.display = 'flex'

        alert({
          id: 'successClientCode',
          status: 'info',
          title: 'Cliente encontrado com sucesso!'
        })

        return
      }
    }
  }, [
    isSuccessSchedulesByOrder,
    isSuccessByClientCode,
    isBothFieldsAreFilled,
    isShowAlert,
    hasOrderCode
  ])

  useEffect(() => {
    if (isErrorSchedulesByOrder && isErrorSchedulesByCustomer) {
      const codeAlertDiv = document.querySelector(
        '#successOrderCode'
      ) as HTMLDivElement

      const clientAlertDiv = document.querySelector(
        '#successClientCode'
      ) as HTMLDivElement

      if (codeAlertDiv) codeAlertDiv.style.display = 'none'
      if (clientAlertDiv) clientAlertDiv.style.display = 'none'
    }
  }, [isErrorSchedulesByOrder, isErrorSchedulesByCustomer])

  useEffect(() => {
    if (isErrorSchedulesByCustomer)
      setIsFailureCount(prevState => prevState + 1)
  }, [isErrorSchedulesByCustomer])

  useEffect(() => {
    if (failureCount === maxFailureCount || failureCount === 1)
      setShowCheckbox(false)
  }, [failureCount])

  useEffect(() => {
    const isValidRequest =
      isErrorSchedulesByCustomer && failureCount < maxFailureCount

    if (isValidRequest) {
      setListAllOrders(false)
      getSchedulesByCustomerId({
        customerId: clientCode,
        filterByScheduleds: false
      })
    }
  }, [isErrorSchedulesByCustomer, failureCount])

  const orderInputInvalid =
    isErrorSchedulesByOrder &&
    !isSuccessSchedulesByOrder &&
    !isSuccessByClientCode

  return (
    <>
      <Flex gap="29px" alignItems={isError ? 'flex-start' : 'flex-end'}>
        <SearchInput
          size="1.5rem"
          isOptionalLabel
          value={orderCode}
          customFontSize="16px"
          label={'Código do pedido'}
          imageIcon="/img/schedules/cart.svg"
          placeholder="Informe o código do pedido"
          errorMessage="Pedido não encontrado, verifique e tente novamente"
          isError={orderInputInvalid}
          onChange={inputValue => {
            setOrderCode(inputValue)
            setIsShowAlert(false)
          }}
        />

        <SearchInput
          size="1.5rem"
          isOptionalLabel
          value={clientCode}
          customFontSize="16px"
          imageIcon="/img/schedules/user.svg"
          label={'Código do cliente'}
          placeholder="Informe o código do cliente"
          errorMessage="Cliente não encontrado ou sem pedidos realizados."
          isError={isErrorSchedulesByCustomer && !isSuccessSchedulesByOrder}
          onChange={inputValue => {
            setClientCode(inputValue)
            setIsShowAlert(false)
          }}
        />

        <Flex minW="199px" mt={isError ? '28.5px' : '0'}>
          <SubmitButton
            onClick={handleGetSchedules}
            isLoading={isLoading}
            isDisabled={!isActiveButton}
          />
        </Flex>
      </Flex>

      {showCheckbox && (
        <Box mt={isError ? '0' : '20px'}>
          <Checkbox
            title=" Mostrar apenas pedidos agendados"
            checked={listAllOrders}
            onChange={() => setListAllOrders(prevState => !prevState)}
          />
        </Box>
      )}

      {showTable && (
        <Box mt="20px">
          <ScheduleTable />
        </Box>
      )}
    </>
  )
}
