import { CreateAndUpdateUserProps } from 'contexts/userContext'

import { usersApi } from './instances/usersApi'

export type UpdateProps = { user: CreateAndUpdateUserProps; id: number }

export type GetAllProps = {
  roleId?: string
  name?: string
  page?: string
}

export const UserService = {
  create(user: CreateAndUpdateUserProps) {
    return usersApi.post('/users', user, { data: null })
  },
  update({ user, id }: UpdateProps) {
    return usersApi.put(`/users/${id}`, user, { data: null })
  },
  remove: (id: number) => {
    return usersApi.delete(`/users/${id}`, { data: null })
  },
  getListRoles: () => {
    return usersApi.get('/roles', { data: null })
  },
  getAll: (filters?: GetAllProps) => {
    const usersPerPage = 20
    const params = {
      roleId: filters?.roleId,
      name: filters?.name,
      page: filters?.page ?? '0',
      size: usersPerPage
    }

    return usersApi.get('/users', {
      data: null,
      params
    })
  }
}
