import { useMutation, useQuery } from 'react-query'
import { ConsumerService, GetWalletDetails } from 'services/consumerService'

export function useQueryGetTransactionByUUID(transactionUUID?: string) {
  return useQuery(
    ['getByTransactionUUID', transactionUUID],
    () => getByTransactionUUID(transactionUUID),
    { retry: 0 }
  )
}

export function useMutateGetTransactionByUUID() {
  return useMutation(
    (transactionUUID?: string) => getByTransactionUUID(transactionUUID),
    { retry: 0 }
  )
}

export function useQueryGetAccountDetailsById(accountId?: string) {
  return useQuery(
    ['getAccountDetails', accountId],
    () => getAccountDetails(accountId),
    { retry: 0 }
  )
}

export function useQueryGetWalletDetailsById({
  accountId,
  walletId
}: GetWalletDetails) {
  return useQuery(
    ['getWalletDetails', { accountId, walletId }],
    () =>
      getWalletDetails({
        accountId,
        walletId
      }),
    { retry: 0 }
  )
}

const getByTransactionUUID = async (transactionUUID?: string) => {
  if (!transactionUUID) return

  const response = await ConsumerService.getByTransactionUUID(transactionUUID)
  return response.data
}

const getAccountDetails = async (accountId?: string) => {
  if (!accountId) return

  const response = await ConsumerService.getByAccountDetails(accountId)
  return response.data
}

const getWalletDetails = async ({ accountId, walletId }: GetWalletDetails) => {
  if (!accountId && !walletId) return

  const response = await ConsumerService.getWalletDetails({
    accountId,
    walletId
  })

  return response.data
}
