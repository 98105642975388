import { Flex } from '@chakra-ui/react'
import { Container, Layout } from 'components/ui'
import React from 'react'

import { FilterArea, Heading, UsersTable } from './components'

const UsersTemplate = () => {
  return (
    <Layout>
      <Container>
        <Main />
      </Container>
    </Layout>
  )
}

export default UsersTemplate

const Main = () => {
  return (
    <Flex flexDir="column" bg="white" width="100%" p="40px" gap="36px">
      <Flex flexDir="column" gap="24px">
        <Heading />
        <FilterArea />
      </Flex>

      <UsersTable />
    </Flex>
  )
}
