import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Flex, Icon, Text, VStack } from 'components/atoms'
import { Modal } from 'components/ui'
import { UncontrolledInput } from 'components/ui/Input'
import { useAuth } from 'contexts/authContext'
import { useSchedules } from 'contexts/scheduleContext'
import { useAlert } from 'hooks/useAlert'
import { translate } from 'internationalization'
import React, { useLayoutEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as z from 'zod'

type Inputs = {
  occurrenceCode: string
}

type CancelOrderProps = {
  isOpen: boolean
  onClose: () => void
  orderId: string
}

const schema = z.object({
  occurrenceCode: z.string().min(2, { message: 'Required' })
})

export const CancelOrder = ({ isOpen, onClose, orderId }: CancelOrderProps) => {
  const [isValidOcorrenceCode, setIsValidOcorrenceCode] = useState(false)
  const { user } = useAuth()
  const { alert } = useAlert()
  const {
    cancelOrder,
    isLoadingCancelOrder,
    isSuccessCancelOrder,
    getSchedulesByOrderId
  } = useSchedules()

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<Inputs>({
    resolver: zodResolver(schema)
  })

  const onSubmit: SubmitHandler<Inputs> = data => {
    cancelOrder({
      orderId: String(orderId),
      occurencyId: data.occurrenceCode,
      userName: user?.name || '',
      sourceIp: '127.0.0.1'
    })
      .then(() => {
        alert({
          id: 'successCancelOrder',
          status: 'success',
          title: 'Pedido cancelado com sucesso'
        })

        getSchedulesByOrderId(orderId)

        reset()
        onClose()
      })
      .catch(err => {
        console.log('err: ', err)
        alert({
          id: 'errorCancelOrder',
          status: 'error',
          title:
            'Ops! Tivemos um problema ao processar sua solicitação, por favor tente novamente'
        })
      })
  }

  useLayoutEffect(() => {
    if (isSuccessCancelOrder) {
      reset()
      onClose()
    }
  }, [isSuccessCancelOrder])

  const modalFooter = (
    <Flex alignItems="center" gap="16px" mt="20px">
      <CancelButton
        onClick={() => {
          onClose()
          reset()
        }}
      />

      <SubmitButton
        title="Cancelar pedido"
        isDisabled={!isValidOcorrenceCode}
        onClick={handleSubmit(onSubmit)}
        isLoading={isLoadingCancelOrder}
      />
    </Flex>
  )

  return (
    <Modal
      heading="Cancelar pedido"
      footer={modalFooter}
      isOpen={isOpen}
      onClose={onClose}
      decreaseMarginTop
      size="lg"
    >
      <VStack spacing="xxs">
        <Flex
          fontSize="16px"
          fontWeight="600"
          w="100%"
          letterSpacing="-0.8px"
          gap="4px"
        >
          <Text color="#221C46">{translate('schedules.cancel')}</Text>
          <Text color="#1B51DC">{orderId}?</Text>
        </Flex>

        <WarningMessage />

        <Flex flexDir="column" gap="16px">
          <UncontrolledInput
            height="48px"
            id="occurrenceCode"
            title="Código da ocorrência"
            isInvalid={!!errors.occurrenceCode?.message}
            isRequired
            requiredMessage="Código da ocorrência  é obrigatório"
            placeholder="Informe o código"
            control={control}
            onChange={event => {
              const inputValue = event.currentTarget.value
              if (inputValue.length >= 1) setIsValidOcorrenceCode(true)
              else setIsValidOcorrenceCode(false)
            }}
          />

          <Text
            letterSpacing="-0.8px"
            color="#221C46"
            fontSize="16px"
            fontWeight="500"
          >
            Atenção ao executar esta ação, ela não poderá ser desfeita.
          </Text>
        </Flex>
      </VStack>
    </Modal>
  )
}

const WarningMessage = () => (
  <Flex
    bg="#FBF2EC"
    h="100%"
    p="16px 12px"
    gap="12px"
    alignItems="center"
    mb="24px"
  >
    <Icon name="warning" color="#221C46" size="xs" />
    <Flex
      fontSize="16px"
      color="#874810"
      fontWeight="600"
      letterSpacing="-0.8px"
    >
      <Text>
        Ao confirmar o cancelamento, os agendamentos de crédito dos
        colaboradores deste pedido serão cancelados.
      </Text>
    </Flex>
  </Flex>
)

const CancelButton = ({ onClick }: { onClick: () => void }) => {
  const defaultStyles = {
    boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
    background: '#DEF3FB',
    color: 'pluxee.interactive.primary'
  }

  return (
    <Button
      h="48px"
      p="4px 8px"
      color="#1B51DC"
      bg="white"
      border="3px solid"
      borderRadius="none"
      borderColor="#1B51DC"
      onClick={onClick}
      _hover={{ ...defaultStyles }}
      _active={{
        ...defaultStyles,
        background: 'pluxee.cta.secondaryPressed',
        color: 'standard.white',
        borderColor: 'pluxee.cta.secondaryPressed',
        boxShadow: '2px 2px 0px 0px rgba(34,28,70,0.2) !important'
      }}
      _focusVisible={{
        ...defaultStyles,
        outline: '3px solid #1B51DC',
        boxShadow: 'none !important'
      }}
    >
      <Text fontSize="14px" fontWeight="700">
        Voltar
      </Text>
    </Button>
  )
}
const SubmitButton = ({
  isLoading,
  isDisabled,
  onClick,
  title
}: {
  title: string
  isLoading: boolean
  isDisabled: boolean
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
}) => {
  return (
    <Button
      h="48px"
      p="4px 8px"
      color="white"
      bg="#1B51DC"
      border="3px solid"
      borderRadius="none"
      borderColor="#1B51DC"
      isLoading={isLoading}
      isDisabled={isDisabled}
      onClick={isDisabled ? undefined : onClick}
      _disabled={{
        bg: '#EFEFEF',
        color: '#B5B2BC',
        borderColor: '#EFEFEF',
        cursor: 'not-allowed'
      }}
      _hover={{
        boxShadow: !isDisabled && '4px 4px 0px 0px #A9A7B6 !important',
        background: !isDisabled && '#DEF3FB',
        color: !isDisabled && 'pluxee.interactive.primary',
        border: !isDisabled && 'none'
      }}
      _active={{
        background: !isDisabled && 'pluxee.cta.secondaryPressed',
        color: !isDisabled && 'standard.white',
        borderColor: !isDisabled && 'pluxee.cta.secondaryPressed',
        boxShadow:
          !isDisabled && '2px 2px 0px 0px rgba(34,28,70,0.2) !important'
      }}
      _focusVisible={{
        background: !isDisabled && '#DEF3FB',
        color: !isDisabled && 'pluxee.interactive.primary',
        boxShadow: !isDisabled && 'none !important'
      }}
    >
      <Text fontSize="14px" fontWeight="700">
        {title}
      </Text>
    </Button>
  )
}
