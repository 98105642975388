import { Flex, Box, Text } from '@chakra-ui/react'
import { Tooltip } from 'components/ui'
import { WalletStatusEnum } from 'models/AccountWallet'
import React from 'react'

export const AccountWalletStatus = ({
  status,
  hideTooltip = false,
  fontSize = '12px',
  padding = '0px 4px'
}: {
  status: WalletStatusEnum
  hideTooltip?: boolean
  fontSize?: string
  padding?: string
}) => {
  const isActive = status === 'ACTIVE'

  const formatWalletStatus = (status: WalletStatusEnum) => {
    const data = {
      ACTIVE: 'Ativa',
      PAUSED: 'Pausada',
      INACTIVE: 'Inativa',
      BLOCKED: 'Desligada',
      BLOCKED_AND_PAUSED: 'Desligada e Pausada',
      BLOCKED_BY_BACEN: 'Bloqueada pelo Bacen'
    }

    return data[status]
  }

  const formatWalletTooltip = (status: WalletStatusEnum) => {
    const data = {
      ACTIVE: '',
      PAUSED: 'Pausada pelo Colaborador, somente ele poderá desfazer esta ação',
      INACTIVE:
        'Inativada pela Pluxee, somente a Pluxee poderá desfazer esta ação.',
      BLOCKED:
        'Desligada pelo Cliente RH, somente ele poderá desfazer esta ação',
      BLOCKED_AND_PAUSED:
        'Desligada pelo Cliente RH, somente ele poderá desfazer esta ação. O status ”Pausada”  foi realizado pelo Colaborador e só poderá ser desfeito por ele.',
      BLOCKED_BY_BACEN:
        'Bloqueada pelo Banco Central por identificação de irregularidade no CPF do Colaborador'
    }

    return data[status]
  }

  return (
    <Tooltip label={hideTooltip ? '' : formatWalletTooltip(status)}>
      <Flex
        bg="standard.white"
        w="fit-content"
        minH="18px"
        p={padding}
        alignItems="center"
        justifyContent="center"
        gap="4px"
        border="1px solid"
        borderColor={isActive ? '#01D253' : '#B30000'}
      >
        <Box
          w="8px"
          h="8px"
          borderRadius="50%"
          bg={isActive ? '#01D253' : '#B30000'}
        />
        <Text
          letterSpacing="-0.6px"
          color="#221C46"
          fontWeight="500"
          fontSize={fontSize}
          lineHeight="16px"
        >
          {formatWalletStatus(status) ?? 'Não disponivel'}
        </Text>
      </Flex>
    </Tooltip>
  )
}
