import { useDisclosure } from '@chakra-ui/react'
import { Tag, Text } from 'components/atoms'
import { Table, TransactionStatus } from 'components/ui'
import { CellProps } from 'components/ui/Table'
import { useAccountWallet } from 'contexts/accountWalletContext'
import { useProfile } from 'contexts/profileContext'
import { useTransactions } from 'contexts/transactionsProvider'
import { useWalletTransaction } from 'contexts/walletTransactionContext'
import { usePagination } from 'hooks/usePagination'
import { translate } from 'internationalization'
import { type WalletTransaction } from 'models/WalletTransaction'
import React, { useEffect } from 'react'
import ContentLoader from 'react-content-loader'
import { formatToBrl } from 'utils/currencyUtils'
import { formatToDateAndTime } from 'utils/dateUtils'
import { capitalizeSentence, limitString } from 'utils/stringUtils'
import * as TransactionUtils from 'utils/transactionsUtils'

import { TransactionDetailsModal } from '../modals/transactionDetails'
import { CreditReversal } from '../operations/creditReversal'
import { ProvisionalCredit } from '../operations/provisionalCredit'
import { ReverseTransaction } from '../operations/reverseTransaction'
import { AppetizerOptions } from '../options/appetizerOptions'
import { ExitsOptions } from '../options/exitsOptions'
import { TransactionOptions } from '../options/transactionOptions'

export const AccountTransactionsTable = () => {
  const { fetchTransactionByUUID } = useTransactions()

  const {
    user,
    isAttendant,
    isAttendant3c,
    isAttendantB2M,
    isSupervisorB2b,
    isBackoffice4c,
    isFraudAndPrevention,
    isManageTransactions,
    isOperation3c,
    isPayments
  } = useProfile()

  const is3cProfile = user?.role?.product_id === 34

  const {
    setWalletId,
    allTransactions,
    getAllTransactions,
    selectedTransaction,
    getRefundableHistory,
    setSelectedTransaction,
    isSuccessCreditInTrust,
    allTransactionPagination,
    isLoadingAllTransactions,
    setOriginalTransactionNSU,
    setOriginalTransactionUUID,
    setOriginalTransaction,
    originalTransactionNSU
  } = useWalletTransaction()

  const { refreshWalletList } = useAccountWallet()

  const { currentPage, handlePrevPage, handleNextPage, handleOnChangePage } =
    usePagination({
      initialPage: allTransactionPagination?.currentPage ?? 0
    })

  const {
    isOpen: isOpenDetails,
    onOpen: onOpenDetails,
    onClose: onCloseDetails
  } = useDisclosure()

  const {
    isOpen: isOpenOutboundTransaction,
    onOpen: onOpenDetailsOutboundTransaction,
    onClose: onCloseOutboundTransaction
  } = useDisclosure()

  const {
    isOpen: isOpenProvisionalCredit,
    onOpen: onOpenProvisionalCredit,
    onClose: onCloseProvisionalCredit
  } = useDisclosure()

  const {
    isOpen: isOpenCreditReversal,
    onOpen: onOpenCreditReversal,
    onClose: onCloseCreditReversal
  } = useDisclosure()

  const {
    isOpen: isOpenReverseTransaction,
    onOpen: onOpenReverseTransaction,
    onClose: onCloseReverseTransaction
  } = useDisclosure()

  const columns: CellProps[] = [
    {
      title: 'Data e hora',
      width: '130px',
      render: (data: Record<keyof WalletTransaction, any>) => {
        const isUndoneTransaction = data.status === 'U'

        return (
          <Text
            fontSize="14px"
            fontWeight="500"
            lineHeight="150%"
            color={isUndoneTransaction ? '#969698' : '#463F5F'}
          >
            {formatToDateAndTime(data.transactionDate, true)}
          </Text>
        )
      }
    },
    {
      title: translate('commons.wallet'),
      width: '100px',
      render: (data: Record<keyof WalletTransaction, any>) => {
        const isUndoneTransaction = data.status === 'U'
        const capitalizedWalletName = capitalizeSentence(
          limitString(data.walletName, 12)
        )

        return (
          <Text
            fontSize="14px"
            fontWeight="500"
            lineHeight="150%"
            color={isUndoneTransaction ? '#969698' : '#463F5F'}
          >
            {capitalizedWalletName}
          </Text>
        )
      }
    },
    {
      title: 'Descrição',
      render: (data: Record<keyof WalletTransaction, any>) => {
        const isWithdrawTransaction = data.type === 'WFT'
        const isTransaction = data.type === 'PTX'
        const isUndoneTransaction = data.status === 'U'

        const capitalizedDescription = isWithdrawTransaction
          ? data.description
          : capitalizeSentence(data.description)

        return (
          <Text
            fontSize="14px"
            fontWeight="500"
            color={isUndoneTransaction ? '#969698' : '#221C46'}
          >
            {isTransaction ? 'Compra em ' : ''}
            {limitString(capitalizedDescription, 50)}
          </Text>
        )
      }
    },
    {
      title: translate('commons.value'),
      render: (data: Record<keyof WalletTransaction, any>) => {
        const isUndoneTransaction = data.status === 'U'

        return (
          <Text
            fontWeight="700"
            fontSize="14px"
            textDecor={isUndoneTransaction ? 'line-through' : 'initial'}
            color={isUndoneTransaction ? '#969698' : '#0F2365'}
          >
            {formatToBrl(Number(data.value))}
          </Text>
        )
      }
    },
    {
      title: 'Trilha',
      hidden: is3cProfile,
      render: (data: Record<keyof WalletTransaction, any>) => {
        return (
          <Tag
            bg="#fff"
            borderRadius="none"
            border="1px solid #908C99"
            p="4px 8px"
            color="#463F5F"
            fontWeight="500"
          >
            {data.voucherTransaction ? 'Voucher' : 'Crédito'}
          </Tag>
        )
      }
    },
    {
      title: translate('commons.status'),
      width: '80px',
      render: (data: Record<keyof WalletTransaction, any>) => {
        return (
          <TransactionStatus
            status={data.status}
            responseCode={data.responseCode}
          />
        )
      }
    },
    {
      title: 'Tipo',
      render: (data: Record<keyof WalletTransaction, any>) => {
        const isUndoneTransaction = data.status === 'U'

        return (
          <Text
            fontSize="14px"
            fontWeight="500"
            color={isUndoneTransaction ? '#969698' : '#221C46'}
          >
            {TransactionUtils.formatTransactionType({ type: data.type })}
          </Text>
        )
      }
    },
    {
      title: '',
      width: '10px',
      render: (data: Record<keyof WalletTransaction, any>) => {
        const isShowCreditReversal =
          !isAttendant &&
          !isAttendant3c &&
          !isAttendantB2M &&
          !isSupervisorB2b &&
          !isBackoffice4c &&
          !isManageTransactions &&
          !isOperation3c &&
          !isPayments &&
          data.status === 'E' &&
          data.type === 'PRC'

        const isShowProvisionalCredit =
          !isAttendant &&
          !isAttendant3c &&
          !isAttendantB2M &&
          !isSupervisorB2b &&
          !isBackoffice4c &&
          !isManageTransactions &&
          !isOperation3c &&
          !isPayments &&
          data.status === 'E'

        const isShowProvisionalCreditForExit =
          (isShowProvisionalCredit && data.type === 'WTX') ||
          (isShowProvisionalCredit && data.type === 'WFT')

        const isShowReverseTransaction =
          !isAttendant &&
          !isAttendant3c &&
          !isAttendantB2M &&
          !isSupervisorB2b &&
          !isFraudAndPrevention &&
          !isOperation3c &&
          !isManageTransactions &&
          !isPayments &&
          data.status === 'E'

        const isShowReverseTransactionForExit =
          (isShowReverseTransaction && data.type === 'WTX') ||
          (isShowReverseTransaction && data.type === 'WFT')

        const isExit = data.type !== 'PTX' && data.operationType === '-'
        const isAppetizer = data.operationType === '+'

        if (isExit) {
          return (
            <ExitsOptions
              data={data}
              setWalletId={() => setWalletId(data?.walletId)}
              onOpenDetails={onOpenDetailsOutboundTransaction}
              setSelectedTransaction={setSelectedTransaction}
              onOpenProvisionalCredit={onOpenProvisionalCredit}
              isShowProvisionalCredit={isShowProvisionalCreditForExit}
              isShowReverseTransaction={isShowReverseTransactionForExit}
              onOpenReverseTransaction={onOpenReverseTransaction}
            />
          )
        }

        if (isAppetizer) {
          return (
            <AppetizerOptions
              data={data}
              setWalletId={() => setWalletId(data?.walletId)}
              isShowCreditReversal={isShowCreditReversal}
              onOpenDetails={onOpenDetailsOutboundTransaction}
              onOpenCreditReversal={onOpenCreditReversal}
              setSelectedTransaction={setSelectedTransaction}
            />
          )
        }

        return (
          <TransactionOptions
            data={data}
            setWalletId={() => setWalletId(data?.walletId)}
            isShowProvisionalCredit={isShowProvisionalCredit}
            isShowReverseTransaction={isShowReverseTransaction}
            onOpenProvisionalCredit={onOpenProvisionalCredit}
            onOpenDetails={onOpenDetails}
            onOpenReverseTransaction={onOpenReverseTransaction}
            setSelectedTransaction={setSelectedTransaction}
          />
        )
      }
    }
  ]

  useEffect(() => {
    handleOnChangePage(0)
  }, [])

  useEffect(() => {
    getAllTransactions({ page: currentPage })
  }, [currentPage])

  let minimumConsumersForPagination = 20
  if (allTransactionPagination?.lastPage) minimumConsumersForPagination = 1

  const isPaginated =
    allTransactions.length >= minimumConsumersForPagination &&
    Number(allTransactionPagination!.totalPages) > 1

  useEffect(() => {
    if (isSuccessCreditInTrust) getAllTransactions({ page: 0 })
  }, [isSuccessCreditInTrust])

  const handleOnClickRow = (data: any) => {
    setSelectedTransaction(data)
    const isExecutedTransaction = data.status === 'E'

    if (data.type === 'PTX') {
      onOpenDetails()

      if (isExecutedTransaction) {
        getRefundableHistory({ transactionUUID: data.transactionUUID! })
      }
    } else {
      if (data.type === 'PRC' || data.type === 'CTR' || data.type === 'PCR') {
        if (isExecutedTransaction) {
          getRefundableHistory({ transactionUUID: data.originUUID })

          fetchTransactionByUUID({ uuid: data.originUUID }).then(
            originalTransaction => {
              setOriginalTransaction(originalTransaction)
              setOriginalTransactionNSU(originalTransaction.nsu)
              setOriginalTransactionUUID(originalTransaction.transactionUUID)
            }
          )
        }
      }

      onOpenDetailsOutboundTransaction()
    }
  }

  if (isLoadingAllTransactions) return <Skeleton />

  return (
    <>
      <Table
        size="sm"
        minWidth="100%"
        textType="initial"
        columns={columns}
        list={allTransactions}
        isPaginated={isPaginated}
        currentPage={currentPage}
        onClickRow={handleOnClickRow}
        handlePrevPage={handlePrevPage}
        handleNextPage={handleNextPage}
        totalPages={allTransactionPagination?.totalPages}
        totalResults={allTransactionPagination?.totalResults}
        handleOnChangePage={pageNumber => handleOnChangePage(pageNumber)}
      />

      <TransactionDetailsModal
        isOpen={isOpenDetails}
        onClose={onCloseDetails}
        transaction={selectedTransaction ?? ({} as WalletTransaction)}
        originalNSU={originalTransactionNSU}
        overrideTransactionType={
          selectedTransaction?.type as TransactionUtils.TransactionLabel
        }
      />

      <TransactionDetailsModal
        isOutboundTransaction
        isOpen={isOpenOutboundTransaction}
        onClose={onCloseOutboundTransaction}
        transaction={selectedTransaction ?? ({} as WalletTransaction)}
        originalNSU={originalTransactionNSU}
        overrideTransactionType={
          selectedTransaction?.type as TransactionUtils.TransactionLabel
        }
      />

      <ProvisionalCredit
        isOpen={isOpenProvisionalCredit}
        onClose={onCloseProvisionalCredit}
        transaction={selectedTransaction ?? ({} as WalletTransaction)}
      />

      <CreditReversal
        isOpen={isOpenCreditReversal}
        onClose={onCloseCreditReversal}
        onSave={() => {
          getAllTransactions({ page: currentPage })
          refreshWalletList()
        }}
        transaction={selectedTransaction ?? ({} as WalletTransaction)}
      />

      <ReverseTransaction
        isOpen={isOpenReverseTransaction}
        onClose={onCloseReverseTransaction}
        transaction={selectedTransaction ?? ({} as WalletTransaction)}
      />
    </>
  )
}

export const Skeleton = () => (
  <ContentLoader viewBox="0 0 380 200">
    <rect x="0" y="10" rx="3" ry="3" width="100%" height="100" />
  </ContentLoader>
)
