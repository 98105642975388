const errorMessages = {
  BLOCKED_BY_TAX_AUTHORITY:
    'Carteira bloqueada devido a restrições impostas pela Receita. Entrada e saída de valores não são permitidos.',
  BLOCKED_BY_BACEN:
    'Carteira bloqueada devido a restrições impostas pelo BACEN. Entrada e saída de valores não são permitidos.',
  BLOCKED_BY_BACEN_PREVENTIVE:
    'Carteira bloqueada devido a restrições preventivas impostas pelo BACEN. Saída de valores não são permitidos.',
  BLOCKED_BY_BACENJUD:
    'Carteira bloqueada devido a restrições impostas pelo BACENJUD. Saída de valores não são permitidos.',
  'login already exist': 'Login de usuário já cadastrado no sistema.',
  'Card Not Unblock reason not allow':
    'Desbloqueio só pode ocorrer pelo Beneficiario via APP.',
  'This Product is not allowed to be manipulated by Backoffice':
    'Este produto não pode ser manipulado pelo BackOffice.'
} as const

export type ErrorMessage = keyof typeof errorMessages

export const formatErrorMessage = (errMessage: ErrorMessage) => {
  return errorMessages[errMessage] ?? errMessage
}
