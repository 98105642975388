/* eslint-disable @typescript-eslint/no-unused-vars */
import { Tag, useDisclosure } from '@chakra-ui/react'
import { Divider, Flex, Text, VStack } from 'components/atoms'
import {
  CloseButton,
  Modal,
  TransactionStatus,
  TransactionHistory
} from 'components/ui'
import { useTransactions } from 'contexts/transactionsProvider'
import { useWalletTransaction } from 'contexts/walletTransactionContext'
import { type WalletTransaction } from 'models/WalletTransaction'
import React, { lazy, Suspense, useEffect } from 'react'
import { formatToBrl } from 'utils/currencyUtils'
import { formatToDateAndTime } from 'utils/dateUtils'
import { capitalizeSentence } from 'utils/stringUtils'
import {
  formatCreditCardNumber,
  formatMccDescription,
  formatResponseCode,
  formatTransactionLabel,
  formatTransactionType,
  TransactionLabel
} from 'utils/transactionsUtils'

const RefundHistory = lazy(
  /* webpackChunkName: 'RefundHistory' */ () =>
    import('./components/RefundHistory')
)

const HasHistoryWarning = lazy(
  /* webpackChunkName: 'HistoryWarning' */ () =>
    import('./components/HasHistoryWarning')
)

type TransactionDetailsModalProps = {
  isOpen: boolean
  onClose: () => void
  transaction: WalletTransaction
  isOutboundTransaction?: boolean
  originalNSU?: string
  overrideTransactionType?: TransactionLabel
  forceToShowRefundHistory?: boolean
  forceShowOriginalTransactionBtn?: boolean
}

export const TransactionDetailsModal = ({
  isOpen,
  onClose,
  transaction,
  originalNSU,
  overrideTransactionType,
  forceToShowRefundHistory = false,
  isOutboundTransaction = false,
  forceShowOriginalTransactionBtn = false
}: TransactionDetailsModalProps) => {
  // const []

  const {
    originalTransaction,
    refundableHistory,
    setRefundableHistory,
    originalTransactionUUID,
    setOriginalTransactionNSU,
    setOriginalTransactionUUID,
    getRefundableHistory,
    setOriginalTransaction
  } = useWalletTransaction()

  const { fetchTransactionByUUID } = useTransactions()

  const hasOriginalTransaction =
    ['PRC', 'CTR', 'PCR'].includes(transaction.type!) &&
    Number(refundableHistory?.refundHistory?.length) > 0 &&
    transaction.status === 'E'

  const tagTitle = transaction.voucherTransaction ? 'Voucher' : 'Crédito'

  const formattedCardType = transaction.isVirtualCard
    ? 'Cartão virtual'
    : 'Cartão físico'

  const formattedCardNumber = transaction.cardNumber
    ? formatCreditCardNumber(transaction.cardNumber)
    : '-'

  const formattedWithdrawFeeDesc =
    isOutboundTransaction && transaction.type === 'WFT'
      ? transaction.description
      : capitalizeSentence(transaction.description ?? '')

  const formattedNSU = originalNSU ?? transaction.nsu
  const modalFooter = <CloseButton onClick={onClose} />

  const {
    isOpen: isOpenReverseCreditDetails,
    onOpen: onOpenReverseCreditDetails,
    onClose: onCloseReverseCreditDetails
  } = useDisclosure()

  const {
    isOpen: isOpenDetails,
    onOpen: onOpenDetails,
    onClose: onCloseDetails
  } = useDisclosure()

  const {
    isOpen: isOpenRefundableModal,
    onOpen: onOpenRefundableModal,
    onClose: onCloseRefundableModal
  } = useDisclosure()

  const hideRefundHistory =
    transaction.type !== 'PTX' && !forceToShowRefundHistory

  const isShowContent =
    forceShowOriginalTransactionBtn && transaction.type !== 'PTX'
      ? true
      : Boolean(
          originalTransactionUUID &&
            originalTransactionUUID !== transaction.transactionUUID
        )

  const onCloseModal = () => {
    onClose()
    setOriginalTransactionNSU(undefined)
    setOriginalTransactionUUID(undefined)
    transaction.type === 'PTX' && setRefundableHistory(undefined)
  }

  const isShowRefundHistory =
    transaction.type !== 'PTX' && isShowContent
      ? true
      : ((refundableHistory?.hasProvisionalCreditHistory ||
          (refundableHistory && refundableHistory.refundHistory?.length > 0)) &&
          !hideRefundHistory) ||
        hasOriginalTransaction

  const handleClickOriginalTransaction = () => {
    if (['PCR'].includes(transaction.type!)) {
      getRefundableHistory({
        transactionUUID: transaction.originUUID!
      }).then(() => {
        onCloseModal()
        onOpenReverseCreditDetails()
      })
    } else {
      if (forceShowOriginalTransactionBtn && originalTransaction) {
        getRefundableHistory({
          transactionUUID: originalTransaction.originUUID!
        })

        fetchTransactionByUUID({ uuid: originalTransaction.originUUID! })
          .then(originalTransaction => {
            setOriginalTransaction(originalTransaction)
            setOriginalTransactionNSU(originalTransaction.nsu)
            setOriginalTransactionUUID(originalTransaction.transactionUUID)
          })
          .then(() => {
            onCloseReverseCreditDetails()
            onOpenDetails()
          })
      } else if (transaction) {
        getRefundableHistory({
          transactionUUID: transaction.originUUID!
        })

        fetchTransactionByUUID({ uuid: transaction.originUUID! })
          .then(response => {
            setOriginalTransaction(response)
            setOriginalTransactionNSU(response.nsu)
            setOriginalTransactionUUID(response.transactionUUID)
          })
          .then(() => {
            onCloseModal()
            onOpenDetails()
          })
      }
    }
  }

  const handeClickPTXTransaction = () => {
    onClose()
  }

  return (
    <>
      <Modal
        size="4xl"
        decreaseMarginTop
        heading="Visão detalhada"
        isOpen={isOpen}
        footer={modalFooter}
        onClose={onCloseModal}
        onCloseComplete={onCloseModal}
        closeOnOverlayClick={false}
      >
        <VStack spacing="xxs" letterSpacing="-0.8px">
          <Flex w="100%" alignItems="center" justifyContent="space-between">
            <Flex flexDir="column" gap="8px">
              <Text fontSize="14px" fontWeight="500">
                ID da transação:
              </Text>
              <Text color="#1B51DC" fontSize="24px" fontWeight="700">
                {transaction.transactionUUID}
              </Text>
              <Flex color="#5A5469" gap="8px" fontSize="14px">
                <Text fontWeight="700">
                  {!originalNSU ? 'NSU:' : 'NSU da transação original: '}
                </Text>
                <Text fontWeight="500">{formattedNSU ?? '-'}</Text>
              </Flex>
            </Flex>

            {transaction.status && (
              <Flex>
                <TransactionStatus status={transaction.status} />
              </Flex>
            )}
          </Flex>

          {isShowRefundHistory && (
            <Suspense fallback={<div />}>
              <HasHistoryWarning
                isShowBackToOriginalButton={isShowContent}
                onClickTransaction={
                  originalTransaction
                    ? handleClickOriginalTransaction
                    : handeClickPTXTransaction
                }
                message={
                  overrideTransactionType
                    ? formatTransactionLabel(overrideTransactionType)
                    : undefined
                }
              />
            </Suspense>
          )}

          <Divider />

          <Flex w="100%" gap="24px">
            <Flex flex="1" flexDir="column" gap="24px">
              <Flex
                gap="8px"
                flexDir="column"
                fontWeight="700"
                color="pluxee.text.primary"
                fontSize="xs"
              >
                <Text>Dados da transação</Text>

                <Flex>
                  <Text minW="280px" fontSize="xxs">
                    Valor:
                  </Text>
                  <Text fontWeight="500" fontSize="xxs">
                    {formatToBrl(Number(transaction.value))}
                  </Text>
                </Flex>

                <Flex>
                  <Text minW="280px" fontSize="xxs">
                    Data:
                  </Text>
                  <Text fontWeight="500" fontSize="xxs">
                    {transaction.transactionDate
                      ? formatToDateAndTime(transaction?.transactionDate)
                      : '-'}
                  </Text>
                </Flex>

                {transaction.description && (
                  <Flex>
                    <Text minW="280px" fontSize="xxs">
                      Descrição:
                    </Text>
                    <Text fontWeight="500" fontSize="xxs">
                      {isOutboundTransaction
                        ? formattedWithdrawFeeDesc
                        : `Compra em ${capitalizeSentence(
                            transaction.description
                          )}`}
                    </Text>
                  </Flex>
                )}

                <Flex>
                  <Text minW="280px" fontSize="xxs">
                    Código de resposta:
                  </Text>
                  <Text fontWeight="500" fontSize="xxs">
                    {transaction.responseCode
                      ? formatResponseCode({ code: transaction.responseCode })
                      : '-'}
                  </Text>
                </Flex>

                {transaction.type && (
                  <Flex>
                    <Text minW="280px" fontSize="xxs">
                      Tipo de transação:
                    </Text>
                    <Text fontWeight="500" fontSize="xxs">
                      {formatTransactionType({
                        type: transaction.type
                      })}
                    </Text>
                  </Flex>
                )}

                {isOutboundTransaction && (
                  <>
                    <Flex>
                      <Text minW="280px" fontSize="xxs">
                        Código da ocorrência:
                      </Text>
                      <Text fontWeight="500" fontSize="xxs">
                        {transaction.occurrenceCode ?? '-'}
                      </Text>
                    </Flex>

                    <Flex>
                      <Text minW="280px" fontSize="xxs">
                        Motivo da ocorrência:
                      </Text>
                      <Text fontWeight="500" fontSize="xxs">
                        {transaction.occurrenceReason ?? '-'}
                      </Text>
                    </Flex>
                  </>
                )}

                <Flex>
                  <Text minW="280px" fontSize="xxs">
                    Modo de entrada:
                  </Text>
                  <Text fontWeight="500" fontSize="xxs">
                    {transaction.entryMode ? transaction.entryMode : '-'}
                  </Text>
                </Flex>

                <Flex>
                  <Text minW="280px" fontSize="xxs">
                    Trilha:
                  </Text>
                  <Text fontWeight="500" fontSize="xxs">
                    <Tag
                      bg="#fff"
                      borderRadius="none"
                      border="1px solid #908C99"
                      p="4px 8px"
                      color="#463F5F"
                      fontWeight="500"
                    >
                      {tagTitle}
                    </Tag>
                  </Text>
                </Flex>

                {!isOutboundTransaction && (
                  <Flex>
                    <Text minW="280px" fontSize="xxs">
                      Cartão:
                    </Text>
                    <Text fontWeight="500" fontSize="xxs">
                      {formattedCardType} - {formattedCardNumber}
                    </Text>
                  </Flex>
                )}

                <Flex>
                  <Text minW="280px" fontSize="xxs">
                    Código de autorização:
                  </Text>
                  <Text fontWeight="500" fontSize="xxs">
                    {transaction.authorizationCode ?? '-'}
                  </Text>
                </Flex>

                <Flex>
                  <Text minW="280px" fontSize="xxs">
                    Número da conta:
                  </Text>
                  <Text fontWeight="500" fontSize="xxs">
                    {transaction.accountId ?? '-'}
                  </Text>
                </Flex>
              </Flex>

              {!hideRefundHistory && (
                <Suspense fallback={<div />}>
                  <RefundHistory
                    isOpenRefundableModal={isOpenRefundableModal}
                    onOpenRefundableModal={onOpenRefundableModal}
                    onCloseRefundableModal={onCloseRefundableModal}
                    originalTransactionNSU={transaction.nsu || ''}
                    originalTransactionUUID={transaction.transactionUUID || ''}
                    refundHistory={refundableHistory?.refundHistory}
                    hasProvisionalCreditHistory={
                      refundableHistory?.hasProvisionalCreditHistory
                    }
                  />
                </Suspense>
              )}

              {!isOutboundTransaction && (
                <>
                  <Divider />

                  <Flex
                    gap="8px"
                    flexDir="column"
                    fontWeight="700"
                    color="pluxee.text.primary"
                    fontSize="xs"
                  >
                    <Text>Estabelecimento</Text>

                    <Flex>
                      <Text minW="280px" fontSize="xxs">
                        Estabelecimento:
                      </Text>
                      <Text fontWeight="500" fontSize="xxs">
                        {capitalizeSentence(transaction.merchantName ?? '-')}
                      </Text>
                    </Flex>

                    <Flex>
                      <Text minW="280px" fontSize="xxs">
                        Código do estabelecimento:
                      </Text>
                      <Text fontWeight="500" fontSize="xxs">
                        {transaction.establishmentCode ?? '-'}
                      </Text>
                    </Flex>

                    <Flex>
                      <Text minW="280px" fontSize="xxs">
                        Código do Adquirente:
                      </Text>
                      <Text fontWeight="500" fontSize="xxs">
                        {transaction.acquirerCode ?? '-'}
                      </Text>
                    </Flex>

                    <Flex>
                      <Text minW="280px" fontSize="xxs">
                        MCC:
                      </Text>
                      <Text fontWeight="500" fontSize="xxs">
                        {transaction.mcc
                          ? formatMccDescription(transaction.mcc)
                          : '-'}
                      </Text>
                    </Flex>
                  </Flex>
                </>
              )}
            </Flex>
          </Flex>

          <TransactionHistory transaction={transaction} />
        </VStack>
      </Modal>

      {originalTransaction &&
        originalTransaction.transactionUUID !== transaction.transactionUUID && (
          <TransactionDetailsModal
            isOpen={isOpenDetails}
            onClose={onCloseDetails}
            transaction={originalTransaction}
            isOutboundTransaction={['PRC', 'CTR', 'PCR'].includes(
              originalTransaction.type!
            )}
            overrideTransactionType={
              originalTransaction?.type as TransactionLabel
            }
          />
        )}

      {originalTransaction && isOpenReverseCreditDetails && (
        <TransactionDetailsModal
          isOpen={isOpenReverseCreditDetails}
          onClose={onCloseReverseCreditDetails}
          transaction={originalTransaction}
          isOutboundTransaction={['PRC', 'CTR', 'PCR'].includes(
            originalTransaction.type!
          )}
          overrideTransactionType={
            originalTransaction?.type as TransactionLabel
          }
          forceToShowRefundHistory={
            Number(refundableHistory?.refundHistory.length) > 0
          }
          forceShowOriginalTransactionBtn
        />
      )}
    </>
  )
}
