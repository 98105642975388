import {
  Button,
  Flex,
  Spinner,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { Icon } from 'components/atoms'
import { Checkbox, Modal } from 'components/ui'
import { useUser } from 'contexts/userContext'
import { useAlert } from 'hooks/useAlert'
import React, { useEffect, useState } from 'react'

import InputSearchUser from './inputSearchUser'

const FilterArea = () => {
  const {
    isLoadingGetAll,
    currentRoleName,
    setCurrentRoleName,
    currentFilters,
    setSearchInputValue,
    getUsersByFilter,
    setSelectedRoleId,
    setIsSearchingByRole
  } = useUser()

  const {
    isOpen: isOpenFilterByRole,
    onClose: onCloseFilterByRole,
    onOpen: onOpenFilterByRole
  } = useDisclosure()

  const isValidRole = currentRoleName && currentRoleName !== 'ALL'

  const defaultStyles = {
    boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
    background: '#DEF3FB',
    color: 'pluxee.interactive.primary'
  }

  const handleResetFilterByRole = () => {
    delete currentFilters.name
    setSearchInputValue('')
    setCurrentRoleName('')
    setSelectedRoleId('ALL')
    setIsSearchingByRole(false)

    getUsersByFilter({
      ...currentFilters,
      name: '',
      roleId: '',
      page: '0'
    })
  }

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between">
        <InputSearchUser />

        <Flex alignItems="end" gap="16px" flexDir="column">
          <Button
            bg="transparent"
            w="169px"
            h="40px"
            isLoading={isLoadingGetAll}
            onClick={onOpenFilterByRole}
            color="#1B51DC"
            border="3px solid"
            borderRadius="none"
            borderColor="#1B51DC"
            rightIcon={<Icon name="filter_list" size="xs" fontWeight="700" />}
            _hover={{ ...defaultStyles }}
            _active={{
              ...defaultStyles,
              background: 'pluxee.cta.secondaryPressed',
              color: 'standard.white',
              borderColor: 'pluxee.cta.secondaryPressed',
              boxShadow: '2px 2px 0px 0px rgba(34,28,70,0.2) !important'
            }}
            _focusVisible={{
              ...defaultStyles,
              outline: '3px solid #1B51DC',
              boxShadow: 'none !important'
            }}
          >
            <Text fontSize="14px" fontWeight="700" letterSpacing="-0.6px">
              Filtrar por perfil
            </Text>
          </Button>

          {isValidRole && (
            <Button
              h="32px"
              p="4px 8px"
              color="#221C46"
              bg="#E3E2FA"
              border="3px solid"
              borderRadius="none"
              borderColor="transparent"
              _hover={{ ...defaultStyles, bg: '#878DFA', color: 'white' }}
              _active={{
                ...defaultStyles,
                background: 'pluxee.cta.secondaryPressed',
                color: 'standard.white',
                borderColor: 'pluxee.cta.secondaryPressed',
                boxShadow: '2px 2px 0px 0px rgba(34,28,70,0.2) !important'
              }}
              _focusVisible={{
                ...defaultStyles,
                outline: '3px solid #1B51DC',
                boxShadow: 'none !important',
                color: '#221C46',
                bg: '#E3E2FA'
              }}
              gap="6px"
              onClick={handleResetFilterByRole}
            >
              <Text fontSize="14px" fontWeight="700">
                {currentRoleName}
              </Text>

              <Icon name="close" size="md" color="#1B51DC" />
            </Button>
          )}
        </Flex>
      </Flex>

      <FilterByRole isOpen={isOpenFilterByRole} onClose={onCloseFilterByRole} />
    </>
  )
}

export default FilterArea

const FilterByRole = ({
  isOpen,
  onClose
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  const { alert } = useAlert()
  const [tempCurrentRoleName, setTempCurrentRoleName] = useState('')

  const {
    roles,
    isLoadingRoles,
    currentFilters,
    selectedRoleId,
    isSuccessGetAll,
    getUsersByFilter,
    setSelectedRoleId,
    setSearchInputValue,
    setCurrentRoleName,
    setIsSearchingByRole
  } = useUser()

  const defaultStyles = {
    boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
    background: '#DEF3FB',
    color: 'pluxee.interactive.primary'
  }

  const handleFilterUsersByRole = () => {
    if (!selectedRoleId)
      return alert({
        id: 'filterToast',
        status: 'warning',
        title: 'Necessário selecionar um tipo para filtro'
      })

    delete currentFilters.name
    setSearchInputValue('')
    setIsSearchingByRole(true)

    getUsersByFilter({
      ...currentFilters,
      name: '',
      roleId: selectedRoleId === 'ALL' ? '' : selectedRoleId,
      page: '0'
    }).then(() => {
      setCurrentRoleName(tempCurrentRoleName)
    })
  }

  useEffect(() => {
    if (isSuccessGetAll) onClose()
  }, [isSuccessGetAll])

  useEffect(() => {
    const checkboxGroupDiv =
      document.querySelectorAll<HTMLInputElement>('.pluxeeCheckbox')
    checkboxGroupDiv?.forEach(item => {
      const checkbox = item?.children[1] as HTMLInputElement

      if (item.innerText !== tempCurrentRoleName) {
        checkbox.style.borderColor = '#908C99'
      } else {
        checkbox.style.borderColor = '#1B51DC'
        checkbox.removeAttribute('data-focus')
        checkbox.removeAttribute('data-focus-visible')
      }
    })
  }, [tempCurrentRoleName])

  const modalFooter = (
    <Flex gap="16px">
      <Button
        bg="transparent"
        w="97px"
        h="48px"
        color="#1B51DC"
        border="3px solid"
        borderRadius="none"
        borderColor="#1B51DC"
        _hover={{ ...defaultStyles }}
        _active={{
          ...defaultStyles,
          background: 'pluxee.cta.secondaryPressed',
          color: 'standard.white',
          borderColor: 'pluxee.cta.secondaryPressed',
          boxShadow: '2px 2px 0px 0px rgba(34,28,70,0.2) !important'
        }}
        _focusVisible={{
          ...defaultStyles,
          outline: '3px solid #1B51DC',
          boxShadow: 'none !important'
        }}
        onClick={() => {
          onClose()
        }}
      >
        <Text fontSize="14px" fontWeight="700" letterSpacing="-0.7px">
          Cancelar
        </Text>
      </Button>

      <Button
        w="82px"
        h="48px"
        bg="#1B51DC"
        color="white"
        border="3px solid"
        borderRadius="none"
        borderColor="#1B51DC"
        _hover={{ ...defaultStyles, border: 'none' }}
        _active={{
          ...defaultStyles,
          background: 'pluxee.cta.secondaryPressed',
          color: 'standard.white',
          borderColor: 'pluxee.cta.secondaryPressed',
          boxShadow: '2px 2px 0px 0px rgba(34,28,70,0.2) !important'
        }}
        _focusVisible={{
          ...defaultStyles,
          boxShadow: 'none !important'
        }}
        onClick={handleFilterUsersByRole}
      >
        <Text fontSize="14px" fontWeight="700" letterSpacing="-0.7px">
          Aplicar
        </Text>
      </Button>
    </Flex>
  )

  if (isLoadingRoles) return <Spinner />

  return (
    <Modal
      heading="Filtrar"
      footer={modalFooter}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      decreaseMarginTop
    >
      <Stack spacing="nano">
        <Flex w="100%" mb="20px">
          <Checkbox
            isRounded
            title="Ver todos os perfis"
            checked={selectedRoleId === 'ALL'}
            onChange={() => {
              setSelectedRoleId('ALL')
              setTempCurrentRoleName('ALL')
            }}
          />
        </Flex>

        {roles?.map(({ id, name }) => (
          <Checkbox
            isRounded
            title={name}
            checked={id === selectedRoleId}
            key={`Checkbox for Role: ${name}`}
            onChange={() => {
              setSelectedRoleId(id)
              setTempCurrentRoleName(name)
            }}
          />
        ))}
      </Stack>
    </Modal>
  )
}
