import { Image } from '@chakra-ui/react'
import { Box, Divider, Flex, Text } from 'components/atoms'
import { translate } from 'internationalization'
import { ResponseCode } from 'models/ResponseCode'
import type {
  TransactionWorkflow,
  WalletTransaction
} from 'models/WalletTransaction'
import React from 'react'
import { formatToBrl } from 'utils/currencyUtils'
import { capitalizeSentence, limitString } from 'utils/stringUtils'
import { TransactionCases } from 'utils/transactionsUtils'

import { TransactionStatus as Status } from '.'
import Tooltip from './Tooltip'

export type TransactionHistoryProps = {
  transaction: WalletTransaction
}

const TransactionHistory = ({
  transaction: { walletValidationHistory }
}: TransactionHistoryProps) => (
  <Flex flexDir="column" justifyContent="flex-start" w="100%" gap="20px">
    <Flex flexDir="column" justifyContent="flex-start">
      {walletValidationHistory && (
        <>
          <Divider mb={5} />
          <Workflow transactionHistory={walletValidationHistory} />
        </>
      )}
    </Flex>
  </Flex>
)

const Header = () => (
  <Flex gap="8px" flexDir="column" mb="8px">
    <Text fontSize="16px" fontWeight="700" color="#221C46">
      {translate('commons.account.history')}
    </Text>

    <Text fontSize="14px" fontWeight="500" color="#221C46">
      {translate('commons.account.historyDescription')}
    </Text>
  </Flex>
)

const Workflow = ({
  transactionHistory
}: {
  transactionHistory: TransactionWorkflow[]
}) => {
  return (
    <>
      <Header />

      <Flex
        p="14px 16px"
        fontSize="14px"
        fontWeight="500"
        color="white"
        bg="#221C46"
        justifyContent="flex-start"
      >
        <Flex gap="8px" alignItems="center" w="130px">
          <Text>Caminho</Text>
          <Tooltip
            iconColor="white"
            label="A coluna “Caminho” apresenta o fluxo de validação que o sistema faz para que a transação ocorra. Caso uma validação seja negada, o sistema automaticamente realiza uma nova conforme a prioridade das carteiras."
          />
        </Flex>
        <Text w="130px">Carteira</Text>
        <Text w="130px">Saldo disponível </Text>
        <Text w="130px">Qtd diária</Text>
        <Text w="130px">Limite disponível</Text>
        <Text w="130px">Status</Text>
      </Flex>

      {transactionHistory?.map((transaction, index) => {
        const limitedDailyLimitTxLabel =
          String(transaction.dailyLimitTx).length >= 6
            ? 'transaç..'
            : 'transações'

        const formattedDailyLimitTx =
          transaction.dailyLimitTx === 999999
            ? 'Ilimitado'
            : `${transaction.dailyLimitTx} ${limitedDailyLimitTxLabel}`

        const formattedWalletName = capitalizeSentence(
          transaction.walletName ?? '-'
        )

        const isBalanceError = transaction?.stepValidation === 'balance'
        const isDailyLimitTxError =
          transaction?.stepValidation === 'dailyLimitTx'
        const isDailyLimitValueError =
          transaction?.stepValidation === 'dailyLimitValue'

        const validationNumber = index + 1

        return (
          <>
            <Flex
              key={`Transaction - ${index}`}
              alignItems="center"
              h="60px"
              paddingLeft="16px"
              fontSize="12px"
              fontWeight="500"
              color="primaryColors.blue.60"
              bg="transparent"
              _odd={{ background: '#F3F3FC' }}
            >
              <Text color="#221C46" fontWeight="500" fontSize="14px" w="123px">
                {validationNumber}ª Validação
              </Text>

              <Flex gap="8px" flexDir="column" w="130px">
                <Flex gap="4px" alignItems="center">
                  <CheckedIcon />
                  <Text color="#221C46" letterSpacing="-0.6px">
                    {limitString(formattedWalletName, 15)}
                  </Text>
                </Flex>

                <Flex w="100%" alignItems="center">
                  <Box
                    bg="#1B51DC"
                    w="16px"
                    h="16px"
                    border="1px solid #1B51DC"
                  />
                  <Box bg="#1B51DC" w="129px" h="1px" />
                </Flex>
              </Flex>

              <Flex gap="8px" flexDir="column" w="130px">
                <Flex gap="4px" alignItems="center">
                  {isBalanceError ? <FailedIcon /> : <CheckedIcon />}
                  <Text color="#221C46" letterSpacing="-0.6px">
                    {formatToBrl(transaction.balance ?? 0)}
                  </Text>
                </Flex>

                <Flex w="100%" alignItems="center">
                  <Box
                    bg={isBalanceError ? 'white' : '#1B51DC'}
                    w="16px"
                    h="16px"
                    border="1px solid #1B51DC"
                  />
                  <Box bg={'#1B51DC'} w="129px" h="1px" />
                </Flex>
              </Flex>

              <Flex gap="8px" flexDir="column" w="130px">
                <Flex
                  gap="4px"
                  alignItems="center"
                  opacity={isBalanceError ? '0' : '1'}
                >
                  {isDailyLimitTxError ? <FailedIcon /> : <CheckedIcon />}
                  <Text color="#221C46" letterSpacing="-0.6px">
                    {formattedDailyLimitTx}
                  </Text>
                </Flex>

                <Flex w="100%" alignItems="center">
                  <Box
                    bg={
                      isBalanceError || isDailyLimitTxError
                        ? 'white'
                        : '#1B51DC'
                    }
                    w="16px"
                    h="16px"
                    border="1px solid"
                    borderColor={'#1B51DC'}
                  />
                  <Box bg={'#1B51DC'} w="129px" h="1px" />
                </Flex>
              </Flex>

              <Flex gap="8px" flexDir="column" w="130px">
                <Flex
                  gap="4px"
                  alignItems="center"
                  opacity={isBalanceError || isDailyLimitTxError ? '0' : '1'}
                >
                  {isDailyLimitValueError ? <FailedIcon /> : <CheckedIcon />}
                  <Text color="#221C46" letterSpacing="-0.6px">
                    {formatToBrl(transaction.dailyLimitValue ?? 0)}
                  </Text>
                </Flex>

                <Flex w="100%" alignItems="center">
                  <Box
                    w="16px"
                    h="16px"
                    border="1px solid"
                    borderColor={'#1B51DC'}
                    bg={
                      isBalanceError ||
                      isDailyLimitTxError ||
                      isDailyLimitValueError
                        ? 'white'
                        : '#1B51DC'
                    }
                  />
                </Flex>
              </Flex>

              <Text flex="1" h="100%" display="flex" alignItems="center">
                {transaction.responseCode && (
                  <TransactionStatus
                    status={transaction.status as TransactionCases}
                    responseDesc={transaction.responseCode}
                  />
                )}
              </Text>
            </Flex>

            <Divider />
          </>
        )
      })}
    </>
  )
}

export default TransactionHistory

const TransactionStatus = ({
  status,
  responseDesc
}: {
  status: TransactionCases
  responseDesc: ResponseCode
}) => {
  const getBgColor = (status: TransactionCases) => {
    const bg = {
      P: 'transparent',
      E: 'transparent',
      F: '#FCECE7',
      U: 'transparent'
    }

    return bg[status as TransactionCases] ?? ''
  }

  return (
    <Flex
      w="100%"
      h="100%"
      alignItems="center"
      justifyContent="flex-start"
      bg={getBgColor(status as TransactionCases)}
      p={2}
    >
      <Status status={status} responseCode={responseDesc} />
    </Flex>
  )
}

const CheckedIcon = () => <Image src="/img/pluxee/check.svg" alt="Check Icon" />
const FailedIcon = () => <Image src="/img/pluxee/failed.svg" alt="Fail Icon" />
