import { Center, Flex, Image, Text } from '@chakra-ui/react'
import { useUser } from 'contexts/userContext'
import { useAlert } from 'hooks/useAlert'
import React, { useEffect } from 'react'
import {
  CancelButton,
  SubmitButton,
  Wrapper
} from 'templates/wallet/operations/wrapper'
import { capitalizeSentence } from 'utils/stringUtils'

type DeleteUserProps = {
  userId: number
  userName: string
  onClose: () => void
  onComplete: () => void
  isOpen: boolean
}

export const DeleteUser = ({
  userId,
  userName,
  onClose,
  onComplete,
  isOpen
}: DeleteUserProps) => {
  const { alert } = useAlert()
  const { removeUser, isLoadingRemove } = useUser()

  const handleDeleteUser = () => {
    removeUser(userId)
      .then(() => {
        onClose()
        onComplete()

        alert({
          id: 'successCreateUser',
          status: 'success',
          title: 'Usuário excluído com sucesso'
        })
      })
      .catch(error => {
        console.error(error)
        return alert({
          id: 'errorCreateUser',
          status: 'error',
          title:
            'Ops! Tivemos um problema ao processar sua solicitação, por favor tente novamente'
        })
      })
  }

  useEffect(() => {
    document.addEventListener('keydown', event => {
      if (event.key === 'Escape') onClose()
    })

    return () => {
      document.removeEventListener('keydown', onClose)
    }
  }, [])

  if (!isOpen) return <></>

  return (
    <Wrapper>
      <Header onClose={onClose} />
      <Flex
        flexDir="column"
        alignItems="center"
        gap="40px"
        p="40px"
        textAlign="center"
      >
        <Flex flexDir="column" gap="8px">
          <Text
            fontWeight="800"
            fontSize="32px"
            letterSpacing="-1.6px"
            color="#221C46"
          >
            Excluir usuário
          </Text>

          <Flex
            flexDir="column"
            fontSize="20px"
            color="#5A5469"
            letterSpacing="-1px"
          >
            <Flex gap="4px">
              <Text fontWeight="500">Deseja excluir o usuário</Text>
              <Text fontWeight="700">{capitalizeSentence(userName)}?</Text>
            </Flex>

            <Text fontWeight="700">Essa ação não poderá ser desfeita.</Text>
          </Flex>
        </Flex>

        <Flex gap="16px">
          <CancelButton onClick={onClose} />
          <SubmitButton
            onClick={handleDeleteUser}
            title="Excluir usuário"
            isLoading={isLoadingRemove}
          />
        </Flex>
      </Flex>
    </Wrapper>
  )
}

const Header = ({ onClose }: { onClose: () => void }) => (
  <Center position="relative" h="160px" w="100%" bg="#FCF1EE">
    <Flex
      position="relative"
      _before={{
        content: "''",
        position: 'absolute',
        top: '128px',
        left: 'calc(50% - 24px)',
        width: '48px',
        height: '24px',
        backgroundColor: 'transparent',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: "url('/img/users/arrow.svg')",
        backgroundRepeat: 'no-repeat'
      }}
    >
      <Image
        w="96px"
        h="96px"
        alt="Delete user icon"
        src="img/users/plx-ic-avatar.png"
      />
    </Flex>

    <Flex
      onClick={onClose}
      top="28px"
      right="32px"
      position="absolute"
      cursor="pointer"
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.58574 10.0001L0.292847 1.70718L1.70706 0.292969L9.99995 8.58586L18.2928 0.292969L19.7071 1.70718L11.4142 10.0001L19.7071 18.293L18.2928 19.7072L9.99995 11.4143L1.70706 19.7072L0.292847 18.293L8.58574 10.0001Z"
          fill="#1B51DC"
        />
      </svg>
    </Flex>
  </Center>
)
